interface AppConfig {
  ENV: string
  APP_NAME: string
  WAPI_URL: string
  WANDERLY_URL: string
  ADMIN_API_URL: string
  TOKEN_STORAGE_KEY: string
  DEFAULT_TOKEN_EXPIRED_HOURS: number
  REMEMBERED_TOKEN_EXPIRED_DAYS: number
}

const Config: AppConfig = {
  ENV: process.env.NODE_ENV ?? 'development',
  APP_NAME: process.env.REACT_APP_NAME ?? 'Wanderly Admin',
  WAPI_URL: process.env.REACT_APP_WAPI_URL ?? 'http://127.0.0.1:81/api/v1',
  ADMIN_API_URL: process.env.REACT_APP_ADMIN_API_URL ?? 'http://127.0.0.1:8000',
  WANDERLY_URL: process.env.REACT_APP_WANDERLY_URL ?? 'http://localhost',
  TOKEN_STORAGE_KEY: process.env.REACT_APP_STORAGE_KEY ?? 'WandaAdminApp',
  DEFAULT_TOKEN_EXPIRED_HOURS: parseInt(process.env.REACT_APP_DEFAULT_TOKEN_EXPIRED_HOURS ?? '24', 10),
  REMEMBERED_TOKEN_EXPIRED_DAYS: parseInt(process.env.REACT_APP_REMEMBERED_TOKEN_EXPIRED_DAYS ?? '7', 10),
}

export default Config
