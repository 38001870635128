import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Plan } from './interfaces'

interface Props {
  plan: Plan | null;
  onSave: (plan: Plan) => void;
}

const PlanForm: React.FC<Props> = ({ plan, onSave }) => {
  const [title, setTitle] = useState(plan?.title);
  const [slug, setSlug] = useState(plan?.slug);
  const [price, setPrice] = useState(plan?.price);
  const [description, setDescription] = useState(plan?.description);
  const [users, setUsers] = useState(plan?.users);
  const [jobs, setJobs] = useState(plan?.jobs);
  const [profiles, setProfiles] = useState(plan?.profiles);
  const [public_, setPublic] = useState(plan?.public);
  const [unlimited, setUnlimited] = useState(plan?.unlimited);
  const [order, setOrder] = useState(plan?.order);
  const [interval, setInterval] = useState(plan?.interval);
  const [iso_currency, setIsoCurrency] = useState(plan?.iso_currency);
  const [implementation_fee, setImplementationFee] = useState(plan?.implementation_fee);
  const [is_customizable, setIsCustomizable] = useState(plan?.is_customizable);
  const [is_system_only, setIsSystemOnly] = useState(plan?.is_system_only);

  const handleSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onSave({
      id: plan?.id,
      stripe_id: null,
      title: title? title : '',
      slug: slug? slug : '',
      price: price? price : 0,
      description: description? description : '',
      users: users? users : 0,
      jobs: jobs? jobs : 0,
      profiles: profiles? profiles : 0,
      public: public_? true : false,
      unlimited: unlimited? true : false,
      created_at: null,
      updated_at: null,
      deleted_at: null,
      order: order? order : 0,
      interval: interval? interval : 'month',
      iso_currency: iso_currency? iso_currency : 'USD',
      implementation_fee: implementation_fee? implementation_fee : 0,
      is_customizable: is_customizable? true : false,
      is_system_only: is_system_only? true : false,
      tiers: plan? plan.tiers: [],
    });
  };

  return (
    <Form>
      <Form.Group controlId="title">
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="slug">
        <Form.Label>Slug</Form.Label>
        <Form.Control
          type="text"
          value={slug}
          onChange={(event) => setSlug(event.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="price">
        <Form.Label>Price</Form.Label>
        <Form.Control
          type="number"
          value={price}
          onChange={(event) => setPrice(parseInt(event.target.value))}
        />
      </Form.Group>

      <Form.Group controlId="description">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="users">
        <Form.Label>Users</Form.Label>
        <Form.Control
          type="number"
          value={users}
          onChange={(event) => setUsers(parseInt(event.target.value))}
        />
      </Form.Group>

      <Form.Group controlId="jobs">
        <Form.Label>Jobs</Form.Label>
        <Form.Control
          type="number"
          value={jobs}
          onChange={(event) => setJobs(parseInt(event.target.value))}
        />
      </Form.Group>

      <Form.Group controlId="profiles">
        <Form.Label>Profiles</Form.Label>
        <Form.Control
          type="number"
          value={profiles}
          onChange={(event) => setProfiles(parseInt(event.target.value))}
        />
      </Form.Group>

      <Form.Group controlId="public">
        <Form.Check
          type="checkbox"
          label="Public"
          checked={public_}
          onChange={(event) => setPublic(event.target.checked)}
        />
      </Form.Group>

      <Form.Group controlId="unlimited">
        <Form.Check
          type="checkbox"
          label="Unlimited"
          checked={unlimited}
          onChange={(event) => setUnlimited(event.target.checked)}
        />
      </Form.Group>

      <Form.Group controlId="order">
        <Form.Label>Order</Form.Label>
        <Form.Control
          type="number"
          value={order}
          onChange={(event) => setOrder(parseInt(event.target.value))}
        />
      </Form.Group>

      <Form.Group controlId="interval">
        <Form.Label>Interval</Form.Label>
        <Form.Control
          type="text"
          value={interval}
          onChange={(event) => setInterval(event.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="iso_currency">
        <Form.Label>ISO Currency</Form.Label>
        <Form.Control
          type="text"
          value={iso_currency}
          onChange={(event) => setIsoCurrency(event.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="implementation_fee">
        <Form.Label>Implementation Fee</Form.Label>
        <Form.Control
          type="number"
          value={implementation_fee}
          onChange={(event) => setImplementationFee(parseInt(event.target.value))}
        />
      </Form.Group>

      <Form.Group controlId="is_customizable">
        <Form.Check
          type="checkbox"
          label="Is Customizable"
          checked={is_customizable}
          onChange={(event) => setIsCustomizable(event.target.checked)}
        />
      </Form.Group>

      <Form.Group controlId="is_system_only">
        <Form.Check
          type="checkbox"
          label="Is System Only"
          checked={is_system_only}
          onChange={(event) => setIsSystemOnly(event.target.checked)}
        />
      </Form.Group>

      <Button variant="primary" type="button" onClick={handleSave}>
        Save
      </Button>
    </Form>
  );
};

export default PlanForm;
