import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../store';
import apiService from '../../../services/apiService'
import { Charge, goLiveData } from './interfaces'

interface SubscriptionState {
  subscription: any;
  invoices: any[];
  payments: any[];
  loading: boolean;
  chargeSaving: boolean;
  error: string | null;
}

const initialState: SubscriptionState = {
  chargeSaving: false,
  subscription: null,
  invoices: [],
  payments: [],
  loading: false,
  error: null,
};

const stripeSlice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    setSubscription(state, action: PayloadAction<any>) {
      state.subscription = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setChargeSaving(state, action: PayloadAction<boolean>) {
      state.chargeSaving = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setInvoices(state, action: PayloadAction<any[]>) {
      state.invoices = action.payload;
    },
    setPayments(state, action: PayloadAction<any[]>) {
      state.payments = action.payload;
    }
  },
});

export const { setSubscription, setLoading, setError, setInvoices, setPayments , setChargeSaving} = stripeSlice.actions;

export const getSubscription = (subscription_id: string, reload=false) => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(setLoading(true));
  const { subscription } = getState().stripe;
  if (subscription && !reload) {
    dispatch(setSubscription(subscription));
    dispatch(setLoading(false));
    return;
  }
  try {
    const response = await apiService.get(`/stripe/subscriptions/${subscription_id}`);
    dispatch(setSubscription(response));
    dispatch(setLoading(false));
  } catch (error : any) {
    dispatch(setError(error.message));
    dispatch(setLoading(false));
  }
};

export const fetchInvoices = (subscription_id: string, reload=false) => async (dispatch: AppDispatch, getState: () => RootState)=> {
  dispatch(setLoading(true));
  const { invoices } = getState().stripe;
  if (invoices.length && !reload) {
    dispatch(setInvoices(invoices));
    dispatch(setLoading(false));
    return;
  }
  try {
    const response = await apiService.get(`/stripe/subscriptions/${subscription_id}/invoices`);
    dispatch(setInvoices(response.invoices));
    dispatch(setLoading(false));
  } catch (error : any) {
    dispatch(setError(error.message));
    dispatch(setLoading(false));
  }
}

export const fetchPayments = (subscription_id: string, refresh=false) => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(setLoading(true));
  const { payments } = getState().stripe;
  if (payments.length && !refresh) {
    dispatch(setPayments(payments));
    dispatch(setLoading(false));
    return;
  }
  try {
    const response = await apiService.get(`/stripe/subscriptions/${subscription_id}/payments`);
    dispatch(setPayments(response.payments));
    dispatch(setLoading(false));
  } catch (error : any) {
    dispatch(setError(error.message));
    dispatch(setLoading(false));
  }
}

export const saveCharge = (charge: Charge) => async (dispatch: AppDispatch) => {
  dispatch(setChargeSaving(true));
  try {
    await apiService.post(`/stripe/charge`, JSON.stringify(charge));
    dispatch(setChargeSaving(false));
    dispatch(fetchPayments(charge.subscription_id, true))
  } catch (error : any) {
    dispatch(setError(error.message));
    dispatch(setChargeSaving(false));
  }
}

export const updatePlan = (subscription_id: string, plan_id: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(setLoading(true));
  const { subscription } = getState().stripe;
  if (subscription) {
    try {
      await apiService.put(`/stripe/subscriptions/${subscription_id}/plan/${plan_id}/`, JSON.stringify({plan_id}));
      dispatch(setLoading(false));
      dispatch(getSubscription(subscription_id, true));
      dispatch(fetchInvoices(subscription_id, true));
      dispatch(fetchPayments(subscription_id, true));
    } catch (error : any) {
      dispatch(setError(error.message));
      dispatch(setLoading(false));
    }
  }
}

export const saveGoLiveDate = (goLive: goLiveData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  try {
    await apiService.put(`/stripe/subscriptions/update-go-live-date`, JSON.stringify(goLive));
    dispatch(setLoading(false));
    dispatch(fetchPayments(goLive.subscription_id, true));
    dispatch(fetchInvoices(goLive.subscription_id, true));
    dispatch(getSubscription(goLive.subscription_id, true));
  } catch (error : any) {
    dispatch(setError(error.message));
    dispatch(setLoading(false));
  }
}

export default stripeSlice.reducer;
