import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// import react-bootstrap components
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import Config from '../../utils/config'
import useAuth from '../../hooks/useAuth'
import PageLoader from '../../components/ui/PageLoader'

import wanderlyLogo from '../../resources/images/logo.svg'
import styles from './auth.module.scss'

const LoginPage: React.FC = () => {
  const [updatingFormField, setUpdatingFormField] = useState(false)
  const { isAuthenticated, error, loading, pageLoading, handleLogin } = useAuth()
  const navigate = useNavigate()

  // Redirect user to dashboard, if already authenticated
  useEffect(() => {
    isAuthenticated && navigate('/')
  }, [isAuthenticated, navigate])

  // Form fields reference
  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)
  const rememberMeRef = useRef<HTMLInputElement>(null)

  const isFormDisabled = error && !updatingFormField

  const submitLoginForm = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault()
    const email = emailRef.current?.value
    const password = passwordRef.current?.value
    const rememberMe: boolean = rememberMeRef.current?.checked ?? false

    setUpdatingFormField(false)

    // TODO:do validation, for email and password
    if (!email || !password) {
      return
    }

    // Attemt login
    handleLogin(email, password, rememberMe)
  }

  if (pageLoading) {
    return <PageLoader />
  }

  return (
    <Container className={`${styles.loginPage} d-flex align-items-center justify-content-center`}>
      <div className={`${styles.loginBlock} content-box`}>
        <header className={styles.formHeader}>
          <img src={wanderlyLogo} alt="" />
          <h3>Log in to {Config.APP_NAME}</h3>
          {error && <Alert variant="danger">{error}</Alert>}
        </header>
        <Form className="d-grid" onSubmit={submitLoginForm}>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={() => {
                setUpdatingFormField(true)
              }}
              ref={emailRef}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={() => {
                setUpdatingFormField(true)
              }}
              ref={passwordRef}
              required
            />
          </Form.Group>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="loginRememberMe">
                <Form.Check type="checkbox" label="Remember me!" ref={rememberMeRef} />
              </Form.Group>
            </Col>
            {/* <Col className="text-right text-end">
              <a
                href="/login/@forget"
                onClick={(e) => {
                  e.preventDefault()
                }}
              >
                Forgot password?
              </a>
            </Col> */}
          </Row>
          <Button variant="primary" type="submit" disabled={isFormDisabled || loading}>
            {loading && <Spinner as="span" size="sm" animation="border" role="status" />} Login
          </Button>
        </Form>
      </div>
    </Container>
  )
}
export default LoginPage
