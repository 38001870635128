import Config from '../utils/config'
import { UserInterface } from '../features/auth/authInterface'

import { useAppDispatch } from '../features/store'
import { sessionExpire } from '../features/auth/authSlice'

export type StorageType = 'localStorage' | 'sessionStorage'

export type StorageObject = {
  accessToken: string
  user: UserInterface
  expires?: number
}

interface hookInterface {
  get: (property?: string) => unknown
  set: (value: StorageObject, expires?: number) => void
  remove: () => void
  clear: () => void
}

const useStorage = (
  storageType: StorageType = 'localStorage',
  storageKey: string = Config.TOKEN_STORAGE_KEY ?? 'WandaAdminApp'
): hookInterface => {
  const storage = window[storageType]

  const dispatch = useAppDispatch()

  const get = (property?: string): StorageObject | string | null => {
    try {
      const data = storage.getItem(storageKey)
      if (!data) return null

      const parsedData = JSON.parse(data)

      // Check if the data expired
      if (parsedData.expires && parsedData.expires < new Date().getTime()) {
        // Update token expiration state
        dispatch(sessionExpire({ tokenExpired: true, error: 'Your session has expired' }))

        // remove stored data
        remove()
        return null
      }

      return property ? (property in parsedData ? parsedData[property] : null) : parsedData
    } catch (error) {
      if (Config.ENV === 'development') {
        console.error(error)
      }
      return null
    }
  }

  const set = (value: StorageObject, expires?: number): void => {
    try {
      const data = { ...value, expires }

      storage.setItem(storageKey, JSON.stringify(data))
    } catch (error) {
      if (Config.ENV === 'development') {
        console.error(error)
      }
    }
  }

  const remove = (): void => {
    try {
      storage.removeItem(storageKey)
    } catch (error) {
      if (Config.ENV === 'development') {
        console.error(error)
      }
    }
  }

  const clear = (): void => {
    try {
      storage.clear()
    } catch (error) {
      if (Config.ENV === 'development') {
        console.error(error)
      }
    }
  }

  return { get, set, remove, clear }
}

export default useStorage
