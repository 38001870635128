import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import useAuth from '../hooks/useAuth'
import DashboardLayout from '../components/layout/DashboardLayout'
import PageLoader from '../components/ui/PageLoader'

const PrivateRoute: React.FC = () => {
  const { isAuthenticated, pageLoading } = useAuth()

  if (pageLoading) {
    return <PageLoader />
  }

  return isAuthenticated ? (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  ) : (
    <Navigate to="/login" replace />
  )
}

export default PrivateRoute
