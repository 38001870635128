import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

const Error404: React.FC = () => (
  <Container className="text-center">
    <Row className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <Col xs={12} md={6}>
        <h1 className="display-4">404</h1>
        <p className="lead">The page you were looking for doesn&apos;t exist.</p>
        <Link to="/">
          <Button variant="primary">Back to Home</Button>
        </Link>
      </Col>
    </Row>
  </Container>
)

export default Error404
