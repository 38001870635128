import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Pagination from 'react-bootstrap/Pagination'

import { AnyObject } from '../../common/commonTypes'

export interface TableColumn {
  key: string
  name: string
  align?: 'left' | 'right' | 'center'
  dataAlign?: 'left' | 'right' | 'center'
  booleanFilter?: boolean
  // eslint-disable-next-line
  formatter?: (value: string | AnyObject | null, row?: any) => string | React.ReactNode | null
}

export interface ActionItem {
  type: 'link' | 'button'
  content: string | React.ReactNode
  className?: string
  title?: string
  urlPattern?: string
  // eslint-disable-next-line
  onClick?: (event: React.MouseEvent<HTMLElement>, row: any) => void
}

export interface ActionItems {
  columnName?: string
  align?: 'left' | 'right' | 'center'
  dataAlign?: 'left' | 'right' | 'center'
  items: ActionItem[]
}

export interface CheckFilterItem {
  key: string
  items: {
    name: string
    match: string // value
    checked?: boolean
  }[]
}

export interface TableOptions {
  title?: string
  striped?: boolean
  bordered?: boolean
  hover?: boolean
  responsive?: boolean
  pagination?: boolean
  dataPerPage?: number
  perPageNumbers?: number[]
  search?: boolean
  searchPlaceholder?: string
  searchableColumns?: string[]
  showListOfColumnsInSearch?: boolean
  checkFilters?: CheckFilterItem[]
  hasBooleanFilter?: boolean
  sortable?: boolean
  sortableColumns?: string[]
  size?: 'sm' | 'md' | 'lg'
  columnsWidth?: string[]
  columnsAlign?: ('start' | 'end' | 'center')[]
  ignoreColumns?: string[]
  actionItems?: ActionItems
}

export interface TableProps {
  columns: TableColumn[]
  // eslint-disable-next-line
  data: any[]
  options?: TableOptions
}

const DataTable: React.FC<TableProps> = ({ columns, data, options = {} }) => {
  const {
    striped = true,
    bordered = true,
    hover = true,
    responsive = true,
    pagination = true,
    dataPerPage = 25,
    perPageNumbers = [25, 50, 75, 100, 200],
    search = true,
    searchPlaceholder = 'Search',
    searchableColumns = [],
    showListOfColumnsInSearch = false,
    checkFilters = [],
    hasBooleanFilter = false,
    // sortable = false,
    // sortableColumns = [],
    size = 'md',
    ignoreColumns = [],
    actionItems,
    title,
  } = options

  // Preparing checkbox filter states
  const filterStates: string[] = []
  checkFilters.forEach((filter) => {
    filter.items.forEach((item) => {
      const filterStr = filter.key + ':' + item.match
      if (item.checked === true && !filterStates.includes(filterStr)) {
        filterStates.push(filterStr)
      }
    })
  })

  const [searchQuery, setSearchQuery] = useState('')
  const [searchColumn, setSearchColumn] = useState('any')
  const [checkedFilters, setCheckedFilters] = useState<string[]>(filterStates)
  const [booleanFilters, setBooleanFilters] = useState<string[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(dataPerPage)

  // Memoize filtered data
  const filteredData = useMemo(() => {
    let filteredRows = data

    // Filter on checkbox
    if (checkedFilters.length) {
      filteredRows = filteredRows.filter((row) => {
        return checkedFilters.some((filter) => {
          const [key, value] = filter.split(':')
          return row[key].toLowerCase().includes(value.toLowerCase())
        })
      })
    }

    // Boolean filters
    if (booleanFilters.length) {
      filteredRows = filteredRows.filter((row) => {
        return booleanFilters.every((filter) => {
          const [key, value] = filter.split(':')
          return row[key] === (value === '1')
        })
      })
    }

    // Function to extract text content from ReactNode
    const extractStringFromElement = (element: React.ReactNode): string => {
      if (typeof element === 'string' || typeof element === 'number' || typeof element === 'boolean') {
        return element.toString()
      }

      if (Array.isArray(element)) {
        return element.map(extractStringFromElement).join('')
      }

      if (React.isValidElement(element) && 'props' in element) {
        const { children } = element.props as { children?: React.ReactNode }
        if (children) {
          return extractStringFromElement(children)
        }
      }

      return ''
    }

    // Filter with search
    if (search && searchQuery !== '') {
      filteredRows = filteredRows.filter((row) => {
        return searchColumn !== 'any'
          ? row[searchColumn].toString().toLowerCase().includes(searchQuery.toLowerCase())
          : searchableColumns.length !== 0
          ? searchableColumns.some((column) => {
              const col = columns.find((col) => col.key === column)
              const originalValue = row[column]?.toString().toLowerCase() ?? ''
              const formatterResult = col?.formatter?.(row[column], row) ?? ''
              const formattedValue = extractStringFromElement(formatterResult).toLowerCase()

              return (
                formattedValue?.includes(searchQuery.toLowerCase()) ||
                originalValue?.includes(searchQuery.toLowerCase())
              )
            })
          : Object.values(row).some((value) => value?.toString().toLowerCase().includes(searchQuery.toLowerCase()))
      })
    }
    return filteredRows
  }, [data, columns, search, searchQuery, searchColumn, searchableColumns, checkedFilters, booleanFilters])

  // Paginate data
  const currentItems = React.useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    return filteredData.slice(indexOfFirstItem, indexOfLastItem)
  }, [currentPage, filteredData, itemsPerPage])

  const totalPages = React.useMemo(() => {
    return Math.ceil(filteredData.length / itemsPerPage)
  }, [filteredData, itemsPerPage])

  // eslint-disable-next-line
  const generateActionItems = (actionItems: ActionItems, row: any) => {
    return (
      <td className={`align-${actionItems.dataAlign ?? actionItems.align ?? 'center'}`}>
        {actionItems.items.map((item, itemIndex) => {
          const { type = 'link', content, urlPattern, className, title, onClick } = item
          const url = urlPattern ? urlPattern.replace(':id', row.id) : '#'

          if (type === 'link') {
            return (
              <Link
                key={`action-row${row.id}-${itemIndex}`}
                to={url}
                className={className}
                title={title}
                onClick={(event) => onClick?.(event, row)}
              >
                {content}
              </Link>
            )
          } else {
            return (
              <button
                key={`action-row${row.id}-${itemIndex}`}
                className={className}
                title={title}
                onClick={(event) => onClick?.(event, row)}
              >
                {content}
              </button>
            )
          }
        })}
      </td>
    )
  }

  const generateHeader = () => {
    return (
      <>
        <tr>
          {columns
            .filter((column) => !ignoreColumns.includes(column.key))
            .map((column) => (
              <th className={`align-${column.align ?? 'center'}`} key={column.key}>
                {column.name}
              </th>
            ))}
          {actionItems && (
            <th className={`align-${actionItems.align ?? 'center'} action-item-column-header`}>
              {actionItems.columnName || 'Actions'}
            </th>
          )}
        </tr>
        {hasBooleanFilter && (
          <tr className="boolean-filter-row">
            {columns
              .filter((column) => !ignoreColumns.includes(column.key))
              .map((column) => (
                <td className={`align-${column.align ?? 'center'}`} key={column.key}>
                  {column?.booleanFilter && (
                    <Form.Select
                      aria-label="Filters"
                      onChange={(e) => {
                        const selectedValue = e.target.value
                        const updatedBooleanFilters = booleanFilters.filter((filter) => {
                          const [key] = filter.split(':')
                          return key !== column.key // Only keep filters that don't have the same key as the current column
                        })

                        if (selectedValue !== '') {
                          updatedBooleanFilters.push(selectedValue)
                        }
                        setBooleanFilters(updatedBooleanFilters)
                      }}
                    >
                      <option value="">--</option>
                      <option value={`${column.key}:1`}>True</option>
                      <option value={`${column.key}:0`}>False</option>
                    </Form.Select>
                  )}
                </td>
              ))}
            {actionItems && <td></td>}
          </tr>
        )}
      </>
    )
  }

  const generateBody = () => {
    return currentItems.map((row, rowIndex) => (
      <tr key={`row-${rowIndex}`}>
        {columns
          .filter((column) => !ignoreColumns.includes(column.key))
          .map((column, columnIndex) => (
            <td
              className={`align-${column.dataAlign ?? column.align ?? 'center'}`}
              key={`cell-${rowIndex}-${columnIndex}`}
            >
              {row[column.key] === null ? (
                '-'
              ) : typeof row[column.key] === 'boolean' ? (
                row[column.key] ? (
                  <i className="fa fa-light fa-check text-success"></i>
                ) : (
                  <i className="fa fa-light fa-x text-danger"></i>
                )
              ) : column.formatter ? (
                column.formatter(row[column.key], row)
              ) : (
                row[column.key]
              )}
            </td>
          ))}
        {actionItems && generateActionItems(actionItems, row)}
      </tr>
    ))
  }

  const generateFooter = () => {
    return (
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div>
          <Dropdown className="d-inline-block me-3">
            <Dropdown.Toggle variant="primary">Items per page: {itemsPerPage}</Dropdown.Toggle>
            <Dropdown.Menu>
              {perPageNumbers.map((option) => (
                <Dropdown.Item
                  key={option}
                  active={option === itemsPerPage}
                  onClick={() => {
                    setItemsPerPage(option)
                    setCurrentPage(1)
                  }}
                >
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <span>
            Showing {Math.min(filteredData.length, (currentPage - 1) * itemsPerPage + 1)}-
            {Math.min(currentPage * itemsPerPage, filteredData.length)} of {filteredData.length}
          </span>
        </div>
        <Pagination>
          <Pagination.Prev disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)} />
          {[...Array(totalPages)].map((_, i) => (
            <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => setCurrentPage(i + 1)}>
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)} />
        </Pagination>
      </div>
    )
  }

  const generateSearchBar = () => {
    return (
      <div
        className={`${title ? 'd-flex justify-content-between' : ''}`}
        style={{ marginTop: '15px', marginBottom: '15px' }}
      >
        {title && (
          <div className="d-flex justify-content-left mr-2">
            <h2>{title}</h2>
          </div>
        )}

        <div className="d-flex justify-content-end">
          <div className="d-flex">
            {checkFilters.map((filter) => {
              // Can be multiple filters
              return (
                <div key={filter.key} className="align-self-center">
                  <Form.Group>
                    {filter.items.map((item) => {
                      const filterStr = filter.key + ':' + item.match

                      return (
                        <Form.Check
                          inline
                          key={filterStr}
                          id={filterStr}
                          type="checkbox"
                          checked={checkedFilters.includes(filterStr)}
                          label={<label htmlFor={filterStr}>{item.name}</label>}
                          onChange={() => {
                            checkedFilters.includes(filterStr)
                              ? setCheckedFilters(checkedFilters.filter((item) => item !== filterStr)) // remove
                              : setCheckedFilters([...checkedFilters, filterStr]) // add
                          }}
                        />
                      )
                    })}
                  </Form.Group>
                </div>
              )
            })}
          </div>

          {search && (
            <div className="align-self-center">
              <InputGroup style={{ maxWidth: '420px' }}>
                {showListOfColumnsInSearch && (
                  <DropdownButton
                    as={InputGroup}
                    variant="outline-secondary"
                    title={
                      searchColumn === 'any'
                        ? 'Any Column'
                        : columns.find((column) => column.key === searchColumn)?.name
                    }
                  >
                    <Dropdown.Item onClick={() => setSearchColumn('any')}>Any Column</Dropdown.Item>
                    {columns
                      .filter((column) => searchableColumns.length === 0 || searchableColumns.includes(column.key))
                      .map((column) => (
                        <Dropdown.Item key={column.key} onClick={() => setSearchColumn(column.key)}>
                          {column.name}
                        </Dropdown.Item>
                      ))}
                  </DropdownButton>
                )}
                <Form.Control
                  type="text"
                  placeholder={searchPlaceholder}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </InputGroup>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      {generateSearchBar()}

      <Table
        className="wandaDataTable"
        striped={striped}
        bordered={bordered}
        hover={hover}
        size={size}
        responsive={responsive}
      >
        <thead>{generateHeader()}</thead>
        <tbody>{generateBody()}</tbody>
      </Table>

      {pagination && generateFooter()}
    </React.Fragment>
  )
}

export default DataTable
