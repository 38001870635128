import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { Link, useParams } from'react-router-dom';
import { RootState } from '../../store';
import PageLoader from '../../../components/ui/PageLoader'

import {
  fetchAgencyInteractions,
  setAgencyInteractions,
} from './interactionsSlice';
import DataTable, { TableColumn, TableOptions } from '../../../components/ui/Table'


const AgencyInteractionsListPage: React.FC = () => {
  const dispatch = useAppDispatch();  
  const { agencyId=0 } = useParams<{ agencyId: string }>(); 
  const { interactions, loading } = useAppSelector((state: RootState) => state.agencyInteractions);  

  // Table columns
  /**
   * {
      "type": "string",
      "type_id": 0,
      "agency_id": 0,
      "candidate_id": 0,
      "tier_id": 0,
      "created_at": "2024-01-30T01:35:25.802Z",
      "updated_at": "2024-01-30T01:35:25.802Z",
      "id": 0
    }
   */
  const columns: TableColumn[] = [
    {
      key: 'type',
      name: 'Type',
      align: 'left',
      formatter: (value, row) => {
        if (!value || typeof value !== 'string') return null
        return row ? <Link to={row.id.toString()}>{value}</Link> : value
      },
    },
    {
      key: 'id',
      name: 'ID',
    },
    {
      key: 'agency_id',
      name: 'Agency ID',
    },
    {
      key: 'candidate_id',
      name: 'Candidate ID',
    },
    {
      key: 'tier_id',
      name: 'Tier ID',
    },
    {
      key: 'created_at',
      name: 'Created At',
      formatter: (value) => {
        if (!value || typeof value!=='string') return null
        const date = new Date(value)
        return date.toLocaleString()
      },
    },
    {
      key: 'updated_at',
      name: 'Updated At',
      formatter: (value) => {
        if (!value || typeof value!=='string') return null
        const date = new Date(value)
        return date.toLocaleString()
      },
    }
  ];
  const options: TableOptions = {
    title: 'Agency Interactions',
    searchPlaceholder: 'Search Type..',
    searchableColumns: ['type', 'candidate_id', 'tier_id', 'created_at', 'updated_at'],
    hasBooleanFilter: true,
    checkFilters: [      
    ]
  };

  useEffect(() => {
    dispatch(fetchAgencyInteractions(+agencyId));
  }, [dispatch]);

  useEffect(() => {
    if (interactions.length) {
      dispatch(setAgencyInteractions(interactions));
    }
  }, [dispatch, interactions]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className="container mx-auto px-4">      
      <Link to={`/agency/${agencyId}`}>Back to Agency</Link>
      <DataTable columns={columns} data={interactions} options={options} />
    </div>
  );
};

export default AgencyInteractionsListPage;