import apiService from '../../../services/apiService';
import { AppDispatch } from '../../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BillingReportState {
  reports: string[];
  isLoading: boolean;
  error: string | null;
}

const initialState: BillingReportState = {
    reports: [],
  isLoading: false,
  error: null,
};

const billingReportsSlice = createSlice({
  name: 'billingReports',
  initialState,
  reducers: {
    setBillingReports(state, action: PayloadAction<string[]>) {
      state.reports = action.payload;
      state.isLoading = false;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setBillingReports, setLoading, setError } = billingReportsSlice.actions;

export default billingReportsSlice.reducer;

export const fetchBillingReports = (agencyId: number) => async (dispatch: AppDispatch) => {
  dispatch(setBillingReports([]));
  dispatch(setLoading(true));

  try {
    const response = await apiService.get(`/agencies/${agencyId}/billing-reports`, {}, dispatch);
    console.log('response', response);
    dispatch(setBillingReports(response.data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(setLoading(false));
  }
};