import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

export interface LoaderProps {
  position?: string
  pageHeight?: string
  loadingText?: string
}

const PageLoader: React.FC<LoaderProps> = ({
  position = 'center',
  pageHeight = '100vh',
  loadingText = 'Loading...',
}) => {
  return (
    <div
      className={`d-flex ${position == 'center' ? 'align-items-center justify-content-center' : ''}`}
      style={{ minHeight: pageHeight }}
    >
      <p>
        <Spinner as="span" size="sm" animation="border" role="status" /> {loadingText}
      </p>
    </div>
  )
}

export default PageLoader
