import React from 'react'
import { Route, Routes } from 'react-router-dom'

import PrivateRoute from './PrivateRoute'
import LoginPage from '../features/auth/LoginPage'
import PlansPage from '../features/billing/PlansPage'
import PlanPage from '../features/billing/PlanPage'
import PriceTierListPage from '../features/billing/PriceTierListPage'
import PriceTierPage from '../features/billing/PriceTierPage'
import DashboardPage from '../features/dashboard/DashboardPage'
import AgencyListPage from '../features/agency/AgencyListPage'
import AssignmentRulesPage from '../features/agency/AssignmentRules/AssignmentRulesPage'
import AgencyBillingPage from '../features/agency/billing/BillingPage'
import AgencySubscriptionPage from '../features/agency/billing/subscriptionPage'
import AgencyInteractionsListPage from '../features/agency/interactions/InteractionsListPage'
import AgencyInteractionPage from '../features/agency/interactions/InteractionPage';
import ReportsListPage from '../features/agency/billingReports/reportsListPage'
import AgencyPage from '../features/agency/AgencyPage'
import AgencyChangelogPage from '../features/changelog/AgencyChangelogPage'
import Error404 from '../components/error/Error404'
import DataIndexerIndex from "../features/dataIndexer/indexPage";
import DisableCandidatePage from "../features/disableCandidates/DisableCanidatePage";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />

      {/* Secure Routes */}
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<DashboardPage />} />
        <Route path="dashboard" element={<DashboardPage />} />

        <Route path="agency" element={<AgencyListPage />} />
        <Route path="agency/:agencyId" element={<AgencyPage />} />
        <Route path="agency/:agencyId/assignment_rules" element={<AssignmentRulesPage/>} />
        <Route path="agency/:agencyId/interactions" element={<AgencyInteractionsListPage/>} />
        <Route path="agency/:agencyId/billing" element={<AgencyBillingPage/>} />
        <Route path="agency/:agencyId/billing/subscriptions/:subscriptionId" element={<AgencySubscriptionPage/>} />
        <Route path="agency/:agencyId/interactions/:interactionId" element={<AgencyInteractionPage/>} />
        <Route path="agency/:agencyId/billing-reports" element={<ReportsListPage />} />


        <Route path="changelogs" element={<AgencyChangelogPage />} />
        <Route path="changelogs/agency" element={<AgencyChangelogPage />} />

        <Route path="plans" element={<PlansPage />} />
        <Route path="plans/:planId" element={<PlanPage />} />

        <Route path="price-tiers" element={<PriceTierListPage />} />
        <Route path="price-tiers/:priceTierId" element={<PriceTierPage />} />
        <Route path="price-tiers/new" element={<PriceTierPage />} />
        <Route path="data-indexer" element={<DataIndexerIndex />} />
        <Route path="disable-candidates" element={<DisableCandidatePage />} />
      </Route>

      {/* 404, not found */}
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}

export default AppRoutes
