import React from 'react'

import Select from 'react-select'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'

import {PriceTier} from './interfaces'

interface FormProps {
    onSubmit: any;
    priceTierState: PriceTier;
    setPriceTierState: React.Dispatch<React.SetStateAction<PriceTier>>;
}

const profileSectionsOptions = [
  { value: 'nurseProfile', label: 'Profile' },
  { value: 'nurseEducations', label: 'Educations' },
  { value: 'nurseGeneralQuestions', label: 'General Questions' },
  { value: 'nurseEmergency', label: 'Emergency' },
  { value: 'nurseEmployment', label: 'Employment History' },
  { value: 'nurseReferences', label: 'References' },
  { value: 'nurseResumes', label: 'Resumes' },
  { value: 'nurseIdentifications', label: 'Identifications' },
  { value: 'nurseLicenses', label: 'Licenses' },
  { value: 'nurseCertifications', label: 'Certifications' },
  { value: 'nurseSkillCategories', label: 'Skill Categories' }
];
const interactionSectionsOptions = [
  { value: 'nurseProfile', label: 'Profile' },
  { value: 'nurseEducations', label: 'Educations' },
  { value: 'nurseGeneralQuestions', label: 'General Questions' },
  { value: 'nurseEmergency', label: 'Emergency' },
  { value: 'nurseEmployment', label: 'Employment History' },
  { value: 'nurseReferences', label: 'References' },
  { value: 'nurseResumes', label: 'Resumes' },
  { value: 'nurseIdentifications', label: 'Identifications' },
  { value: 'nurseLicenses', label: 'Licenses' },
  { value: 'nurseCertifications', label: 'Certifications' },
  { value: 'nurseSkillCategories', label: 'Skill Categories' }
];

const interactionTypeOptions = [
  { label: 'Application', value: 'Application' },
  { label: 'Quick Apply', value: 'Quick Apply' },
  { label: 'Profile', value: 'Profile' },
  { label: 'Contact', value: 'Contact' },
];

const PriceTierForm: React.FC<FormProps> = ({onSubmit, priceTierState, setPriceTierState}) => {
    const getCurrentValue = (value: string[] | null, options: { value: string, label: string }[] = []) => {
      if (!value) return [];
      const datum = options.reduce((acc: { [key: string]: string }, item) => {
        acc[item.value] = item.label;
        return acc;
      }, {});
      return value.map((item) => {
        return {
          value: item,
          label: datum[item] || item,
        };
      });
    };
    const displayConditions= () => {
      if (priceTierState?.strategy === 'ProfilePercentage') {
        return (
          <InputGroup className="sm-6">
            <Form.Control
              type="number"
              placeholder="Min Percentage"
              value={priceTierState?.conditions?.min_percentage}
              //disabled={!priceTierState?.id}
              onChange={(e) => {
                const currentConditions = priceTierState?.conditions ? priceTierState.conditions : {};
                setPriceTierState({
                  ...priceTierState,
                  conditions: { ...currentConditions, min_percentage: e.target.value }
                })
              }}
            />
            <Form.Control
              type="number"
              placeholder="Max Percentage"
              value={priceTierState?.conditions?.max_percentage}
              //disabled={!priceTierState?.id}
              onChange={(e) => {
                const currentConditions = priceTierState?.conditions ? priceTierState.conditions : {};

                setPriceTierState({
                  ...priceTierState,
                  conditions: { ...currentConditions, max_percentage: e.target.value }
                })
              }}
            />
          </InputGroup>)
     }
     if (priceTierState.strategy == 'InteractionType') {
       return (
         <InputGroup className="sm-6">
           <Form.Group as={Col}>
             <Form.Label htmlFor="interaction_types">Interaction Types</Form.Label>
             <Select
               id="interaction_types"
               options={interactionTypeOptions}
               isMulti
               value={getCurrentValue(priceTierState?.conditions?.value, interactionTypeOptions)}
               onChange={(data) => {
                 const values = Object.values(data.map((option) => option.value));
                 console.log('values', values);
                 const currentConditions = priceTierState?.conditions ? priceTierState.conditions : {};
                 setPriceTierState({ ...priceTierState, conditions: { ...currentConditions, value: values } })
               }}
             />
           </Form.Group>
         </InputGroup>
       )
     }
     if(priceTierState.strategy == 'ProfileSections') {
       return (
         <InputGroup className="sm-6">
           <Form.Group as={Col}>
             <Form.Label htmlFor="profile_sections">Profile Sections</Form.Label>
             <Select
               id="profile_sections"
               options={profileSectionsOptions}
               isMulti
               value={getCurrentValue(priceTierState?.conditions?.value, profileSectionsOptions)}
               onChange={(data) => {
                 const values = Object.values(data.map((option) => option.value));
                 console.log('values', values);
                 const currentConditions = priceTierState?.conditions ? priceTierState.conditions : {};
                 setPriceTierState({ ...priceTierState, conditions: { ...currentConditions, value: values } })
               }}
             />
           </Form.Group>
         </InputGroup>
       )
     }
     if(priceTierState.strategy == 'InteractionSections' ){
       const mandatorySections = priceTierState?.conditions?.value?.mandatorySections || [];
       const optionalSections = priceTierState?.conditions?.value?.optionalSections || []
      return (
        <InputGroup className="sm-6">
          <Form.Group as={Col}>
            <Form.Label htmlFor="interaction_sections">Interaction Sections</Form.Label>
            <Select
              id="interaction_mandatory_sections"
              options={interactionSectionsOptions}
              isMulti
              value={getCurrentValue(mandatorySections, interactionSectionsOptions)}
              onChange={(data) => {
                const values = Object.values(data.map((option) => option.value));
                const currentConditions = priceTierState?.conditions ? priceTierState.conditions : {};
                const currentValues = currentConditions?.value || [];
                setPriceTierState({
                  ...priceTierState,
                  conditions: {
                    ...currentConditions,
                    value: {
                      ...currentValues,
                      mandatorySections: values
                    }
                  }})
              }}
            />
            <Select
              id="interaction_optional_sections"
              options={interactionSectionsOptions}
              isMulti
              value={getCurrentValue(optionalSections, interactionSectionsOptions)}
              onChange={(data) => {
                const values = Object.values(data.map((option) => option.value));
                const currentConditions = priceTierState?.conditions ? priceTierState.conditions : {};
                const currentValues = currentConditions?.value || [];
                setPriceTierState({
                  ...priceTierState,
                  conditions: {
                    ...currentConditions,
                    value: {
                      ...currentValues,
                      optionalSections: values
                    }
                  }})
              }}
            />
          </Form.Group>
        </InputGroup>
      )
      }
    }
    return (
        <React.Fragment>
            <Form onSubmit={onSubmit}>                    
                    <Card>
                        <Card.Body>
                            <Form.Group as={Col}>
                                <Form.Label>Price Tier Name</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder="Price Tier Name"
                                        value={priceTierState?.name}                                        
                                        onChange = {(e) => {                                            
                                            setPriceTierState({...priceTierState, name: e.target.value}) 
                                        }}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Description</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder="Description"
                                        value={priceTierState?.description}                                        
                                        onChange={(e) =>{
                                            setPriceTierState({...priceTierState, description: `${e.target.value}`}) 
                                        }}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Price</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder="Price"
                                        value={priceTierState?.price}
                                        //disabled={!priceTierState?.id}
                                        onChange={(e) => {
                                            setPriceTierState({...priceTierState, price: Number(e.target.value)}) 
                                        }}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Strategy</Form.Label>
                                <InputGroup>
                                    <Form.Select
                                        placeholder="Strategy"
                                        value={priceTierState?.strategy}                                        
                                        defaultValue="ProfilePercentage"
                                        onChange={(e) => {
                                            console.log('strategy changed', e.target.value);
                                            setPriceTierState({...priceTierState, strategy: e.target.value}) 
                                        }}
                                    >
                                        <option value="ProfilePercentage">ProfilePercentage</option>                                        
                                        <option value="InteractionType">InteractionType</option>
                                        <option value="ProfileSections">ProfileSections</option>
                                        <option value="InteractionSections">InteractionSections</option>
                                    </Form.Select>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Conditions</Form.Label>
                              {displayConditions()}
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Agency Id</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder="Agency Id"
                                        value={`${priceTierState?.agency_id??''}`}
                                        //disabled={!priceTierState?.id}
                                        onChange={(e) => {
                                            setPriceTierState({...priceTierState, agency_id: Number(e.target.value) || null}) 
                                        }}
                                    />
                                </InputGroup>
                            </Form.Group>                            
                            <Form.Group as={Col}>
                                <Form.Label>Public</Form.Label>
                                <InputGroup>
                                <Form.Check // prettier-ignore
                                    type="switch"                                    
                                    id="public-switch"
                                    label="Make this public"
                                    checked={priceTierState?.public}
                                    onChange={(e) => {
                                        setPriceTierState({...priceTierState, public: e.target.checked})
                                    }}
                                />
                                </InputGroup>
                            </Form.Group>
                        </Card.Body>                        
                        <Card.Footer>
                            <div className="d-flex justify-content-center">
                                <Button
                                    variant="success"
                                    className="w-50"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                        onSubmit(e)
                                    }}
                                >
                                    Save
                                </Button>                                
                            </div>
                        </Card.Footer>
                    </Card>
                </Form>
        </React.Fragment>
    );

};

export default PriceTierForm;
