import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { useAppDispatch, useAppSelector } from '../../store'
import PageLoader from '../../../components/ui/PageLoader'
import AssignmentRuleForm, { RuleFormRef } from './form/AssignmentRuleForm'
import DataTable, { TableColumn, TableOptions } from '../../../components/ui/Table'
import { fetchCurrentAgency } from '../agencySlice'
import { fetchAgencyRecruiters, deleteAgencyAssignmentRule, fetchAgencyAssignmentRules } from './assignmentRulesSlice'

import { AnyObject } from '../../../common/commonTypes'

const AssignmentRulesPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { currentAgency, loading, submissionLoading } = useAppSelector((state) => state.agency)
  const { rules, deletingRule } = useAppSelector((state) => state.assignmentRules)
  const { jobTypes } = useAppSelector((state) => state.common)
  const [showFormModal, setShowFormModal] = useState(false)
  const [editForm, setEditForm] = useState(false)
  const [deleteRuleSubmitted, setDeleteRuleSubmitted] = useState(false)
  const [showDeleteRuleModal, setShowDeleteRuleModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState<AnyObject>({})
  const { agencyId } = useParams()

  // Rule Form Ref
  const ruleFormRef = useRef<RuleFormRef>(null)

  // Calling API Ref
  const shouldCallAPI = useRef(true)

  // Fetch current agency's recruiters
  useEffect(() => {
    dispatch(fetchAgencyRecruiters(Number(agencyId)))
  }, [dispatch, agencyId])

  useEffect(() => {
    if (!currentAgency) return

    dispatch(fetchAgencyAssignmentRules(Number(currentAgency.id)))
  }, [currentAgency, dispatch])

  useEffect(() => {
    if (!deleteRuleSubmitted) return
    if (deletingRule) return

    setShowDeleteRuleModal(false)
    setDeleteRuleSubmitted(false)
  }, [deletingRule, deleteRuleSubmitted])

  useEffect(() => {
    // Should the API only first time it renders
    if (shouldCallAPI.current) {
      shouldCallAPI.current = false
      dispatch(fetchCurrentAgency(Number(agencyId)))
    }
  }, [dispatch, agencyId])

  // Get Job Type name by ID
  const getJobTypeById = (jobTypeId: number) => {
    const jobType = jobTypes.find((type) => type.id === jobTypeId)
    return jobType ? jobType.name : 'Unknown'
  }

  // Action on delete button
  const onClickDeleteRuleButton = (event: React.MouseEvent<HTMLElement>, row: AnyObject) => {
    event?.preventDefault()
    setSelectedRow(row)
    setShowDeleteRuleModal(true)
  }

  const handleDeleteRule = (rule: AnyObject) => {
    if (!currentAgency) {
      console.log('No agency data found')
      return
    }

    setDeleteRuleSubmitted(true)
    dispatch(deleteAgencyAssignmentRule(Number(agencyId), rule))
  }

  // Action on delete button
  const onClickEditRuleButton = (event: React.MouseEvent<HTMLElement>, row: AnyObject) => {
    event?.preventDefault()
    setSelectedRow(row)
    setEditForm(true)
  }

  // Handle form submit, from modal
  const handleFormSubmit = () => {
    // Call submitForm method from AssignmentRuleForm
    if (ruleFormRef.current) ruleFormRef.current.submitForm()
  }

  const ruleOptions: AnyObject = {
    professions: 'Professions',
    facilities: 'Facilities',
    specialities: 'Specialities',
    states: 'States',
    cities: 'Cities',
    jobProperties: 'Job Properties',
  }

  const columns: TableColumn[] = [
    {
      key: 'humanized_rule',
      name: 'Rules',
      align: 'left',
      formatter: (value, row) => {
        if (!value)
          return (
            <ul>
              <li>
                <b>Default</b>
              </li>
            </ul>
          )

        const formattedRules = Object.entries(row)
          .map(([key, data]) => {
            if (typeof data !== 'object') {
              return null // Skip this iteration
            }

            const items = Object.values(data as AnyObject)
            if (!(key in ruleOptions) || items.length === 0) return null

            const formattedValues = items.map((item) => {
              if (key === 'jobProperties') {
                let jobTypeString = (Object.entries(item)[0] as AnyObject)?.job_type
                if (!jobTypeString) {
                  jobTypeString = (Object.entries(item)[0] as AnyObject)?.[1]
                }

                const jobTypeId = parseInt(jobTypeString)
                const jobType = getJobTypeById(jobTypeId)
                return `Type: ${jobType}`
              }
              return key == 'states' ? item.code : item.name
            })

            return (
              <li key={key}>
                <b>{ruleOptions[key]}</b>: [{formattedValues.join(', ')}]
              </li>
            )
          })
          .filter((key) => key !== null)

        return <ul>{formattedRules}</ul>
      },
    },
    {
      key: 'recruiters',
      name: 'Recruiters',
      align: 'left',
      formatter: (value) => {
        if (!value) return null

        if (typeof value === 'object') {
          const recruiters = Object.values(value).map((item: AnyObject) => item.full_name)
          return recruiters.join(', ')
        }

        return value
      },
    },
    {
      key: 'created_at',
      name: 'Created',
      align: 'left',
      formatter: (value) => {
        if (!value || typeof value !== 'string') return null
        const date = new Date(value)
        return date.toLocaleString()
      },
    },
  ]

  const options: TableOptions = {
    title: `${currentAgency?.name} Assignment Rules`,
    searchPlaceholder: 'Search rule..',
    searchableColumns: ['humanized_rule', 'recruiters'],
    hasBooleanFilter: true,
    actionItems: {
      items: [
        {
          type: 'link',
          content: <i className="fa fa-light fa-edit text-primary"></i>,
          className: 'action-item-link',
          title: 'Edit rule',
          urlPattern: '/@edit',
          onClick: onClickEditRuleButton,
        },
        {
          type: 'link',
          content: <i className="fa fa-light fa-x text-danger"></i>,
          className: 'action-item-link',
          title: 'Delete rule',
          urlPattern: '/@delete',
          onClick: onClickDeleteRuleButton,
        },
      ],
    },
  }

  return (
    <React.Fragment>
      {loading ? (
        <PageLoader position="left" />
      ) : (
        <React.Fragment>
          <div>
            <div className="d-flex justify-content-end page-header">
              <Button
                variant="success"
                className="edit-button"
                onClick={() => {
                  setShowFormModal(true)
                }}
              >
                New Rule
              </Button>
            </div>
            <DataTable columns={columns} data={rules} options={options} />
          </div>
        </React.Fragment>
      )}
      <Modal
        show={showFormModal || editForm}
        onHide={() => {
          setShowFormModal(false)
          setSelectedRow({})
          setEditForm(false)
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{editForm && 'Edit'} Assignment Rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssignmentRuleForm
            ref={ruleFormRef}
            agencyId={Number(agencyId)}
            selectedRule={editForm && selectedRow ? selectedRow : null}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center">
            <Button variant="success" className="w-50" onClick={handleFormSubmit}>
              {editForm ? 'Update' : 'Save'} Rule
            </Button>
            <Button
              variant="secondary"
              className="w-50"
              onClick={() => {
                setShowFormModal(false)
                setSelectedRow({})
                setEditForm(false)
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={showDeleteRuleModal}
        backdrop="static"
        onHide={() => {
          setShowDeleteRuleModal(false)
          setSelectedRow({})
        }}
        keyboard={false}
      >
        <Modal.Header closeButton={!submissionLoading}>
          <Modal.Title>Are you sure you can to delete this rule?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This action cannot be undone. Please confirm you can to delete this rule.</p>
          <p>{selectedRow.humanized_rule}</p>
        </Modal.Body>
        <Modal.Footer className="text-center">
          <div className="d-flex justify-content-center">
            <Button
              variant="danger"
              className="w-50"
              onClick={() => {
                handleDeleteRule(selectedRow)
              }}
              disabled={deletingRule}
            >
              Yes, delete!
            </Button>
            <Button
              variant="secondary"
              className="w-50"
              onClick={() => {
                setShowDeleteRuleModal(false)
                setSelectedRow({})
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default AssignmentRulesPage
