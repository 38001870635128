/** src/components/features/billing/ */
import React, { useEffect } from 'react';


import PageLoader from '../../components/ui/PageLoader'
import { useParams, Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store';
import { fetchPriceTier, updatePriceTier, createPriceTier } from './priceTierSlice';
import { PriceTier } from './interfaces'
import PriceTierForm  from './priceTierForm'

const defaultPriceTierState: PriceTier = {
  id: 0,
  name: '',
  description: '',
  price: 0,
  strategy: 'ProfilePercentage',
  conditions: '',
  public: false,
  agency_id: null,
  version: 2,
  created_at: (new Date()).toISOString(),
  updated_at: (new Date()).toISOString(),
};

const PriceTierPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { priceTierId=0 } = useParams<{ priceTierId: string }>();  

  useEffect(() => {    
    dispatch(fetchPriceTier(+priceTierId));    
  }, [dispatch, priceTierId]);

  const {priceTier, loading} = useAppSelector((state) => state.priceTiers);
  const [priceTierState, setPriceTierState] = React.useState<PriceTier>(defaultPriceTierState);

  useEffect(() => {
    if(!priceTier) return;
    setPriceTierState(priceTier);
  }, [priceTier]);

  const handleSubmission = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();    
    if(priceTierId){
        dispatch(updatePriceTier(priceTierState));
    }else{
        dispatch(createPriceTier(priceTierState));
    }

  }

  return (
    <React.Fragment>
        <h1>Price Tier Details</h1>
        <Link to="/price-tiers">Back to Price Tiers</Link>
        {loading ? (<PageLoader pageHeight="auto" loadingText="Loading price tier details.." />):
        (
            <React.Fragment>
                <PriceTierForm onSubmit={handleSubmission} priceTierState={priceTierState} setPriceTierState={setPriceTierState} />
            </React.Fragment>   
        )}
    </React.Fragment>
    
  );
};

export default PriceTierPage;