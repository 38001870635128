import React, { useEffect, useRef } from 'react'

import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

import { useAppDispatch, useAppSelector } from '../store'
import { fetchAgencyChangelogs } from './changelogSlice'
import { ChangeItemInterface } from './changelogInterface'
import PageLoader from '../../components/ui/PageLoader'
import DataTable, { TableColumn, TableOptions } from '../../components/ui/Table'
import Config from '../../utils/config'

const AgencyChangelogPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { agencies, loading, error } = useAppSelector((state) => state.changelog)

  // Calling API Ref
  const shouldCallAPI = useRef(true)

  // Fetch agencies
  useEffect(() => {
    // Should the API only first time it renders
    if (shouldCallAPI.current) {
      shouldCallAPI.current = false
      dispatch(fetchAgencyChangelogs())
    }
  }, [dispatch])

  // Column name mapping
  const columnNameMap: { [key: string]: { name: string; type?: string } } = {
    'agencies.name': {
      name: 'Name',
    },
    'agencies.travel_nurses': {
      name: 'Travel RN Jobs',
      type: 'boolean',
    },
    'agencies.travel_allied_professionals': {
      name: 'Travel Allied Jobs',
      type: 'boolean',
    },
    'agencies.travel_lvn_lpn': {
      name: 'Travel RN Jobs',
      type: 'boolean',
    },
    'agencies.test_account': {
      name: 'Test Account',
      type: 'boolean',
    },
    'agencies.search_weight': {
      name: 'Search Ranking',
    },

    'agency_profiles.monthly_budget': {
      name: 'Monthly Budget',
    },
    'agency_profiles.min_posted_jobs': {
      name: 'Minimum Jobs Limit',
    },
    'agency_profiles.posting_jobs': {
      name: 'Jobs Posted',
      type: 'boolean',
    },
    'agency_profiles.trial': {
      name: 'On Trial',
      type: 'boolean',
    },
    'agency_profiles.unlimited_budget': {
      name: 'Unlimited Budget',
      type: 'boolean',
    },

    'agency_features.enable_permanent_jobs': {
      name: 'Permanent Jobs',
      type: 'boolean',
    },
    'agency_features.enable_chat_anonymously': {
      name: 'Chat Anonymously',
      type: 'boolean',
    },
    'agency_features.enable_quick_apply': {
      name: 'Quick App',
      type: 'boolean',
    },
    'agency_features.enable_application': {
      name: 'Full App',
      type: 'boolean',
    },
    'agency_features.enable_interested_flow': {
      name: 'Interested Flow',
      type: 'boolean',
    },
    'agency_features.enable_hot_lead': {
      name: 'Hot Leads',
      type: 'boolean',
    },
    'agency_features.enable_quick_apply_skills_checklist': {
      name: 'QA Skills Checklist',
      type: 'boolean',
    },

    'agency_upsell_config.quick_apply_enabled': {
      name: 'Upsell Quick App',
      type: 'boolean',
    },
    'agency_upsell_config.full_apply_enabled': {
      name: 'Upsell Full App',
      type: 'boolean',
    },

    'agency_marketing_feed_configs.enable_iterable': {
      name: 'Iterable Job Feed',
      type: 'boolean',
    },
    'agency_marketing_feed_configs.enable_talent': {
      name: 'Talent Job Feed',
      type: 'boolean',
    },
    'agency_marketing_feed_configs.enable_ziprecruiter': {
      name: 'ZipRecruiter Job Feed',
      type: 'boolean',
    },

    'agency_budget_redirection_config.job_type': {
      name: 'Budget Redirection',
      type: 'boolean',
    },
  }

  // Table columns
  const columns: TableColumn[] = [
    {
      key: 'id',
      name: 'ID',
    },
    {
      key: 'admin_email',
      name: 'Who',
    },
    {
      key: 'agency_name',
      name: 'Agency',
    },
    {
      key: 'applied_at',
      name: 'Changed At',
      formatter: (value) => {
        if (!value || typeof value !== 'string') return null

        const date = new Date(value)
        return date.toLocaleString()
      },
    },
    {
      key: 'changes',
      name: 'What Changed',
      align: 'left',
      formatter: (data) => {
        if (!data) return null

        let parsedData
        if (typeof data === 'string') {
          try {
            parsedData = JSON.parse(data)
          } catch (error) {
            console.error('Error parsing JSON:', error)
            return null
          }
        } else {
          parsedData = data
        }

        const formattedChanges = parsedData.map((item: ChangeItemInterface) => {
          const [key, value] = Object.entries(item)[0]
          const { new: newValue, old: oldValue } = value

          const formattedKey = columnNameMap[key]?.name || key.replace(/\./g, '["').concat('"]')
          let oldValueText = typeof oldValue === 'string' ? `"${oldValue}"` : oldValue
          let newValueText = typeof newValue === 'string' ? `"${newValue}"` : newValue

          // Check if the column type is boolean
          if (columnNameMap[key]?.type === 'boolean') {
            // Convert boolean values to 'True' and 'False'
            oldValueText = oldValue ? 'True' : 'False'
            newValueText = newValue ? 'True' : 'False'
          }

          return (
            <li key={key}>
              <b>{formattedKey}</b> was changed from <b>{oldValueText}</b> to <b>{newValueText}</b>
            </li>
          )
        })

        return <ul>{formattedChanges}</ul>
      },
    },
  ]

  // Table options
  const options: TableOptions = {
    title: 'Agency Change Log',
    searchPlaceholder: 'Search change log..',
    searchableColumns: ['agency_name', 'admin_email'],
  }

  if (error) {
    // TODO: Handle errors
    if (Config.ENV === 'development') {
      console.error(error)
    }
  }

  return (
    <Row>
      <Container fluid>
        {loading ? (
          <PageLoader position="left" loadingText="Loading agency change logs.." />
        ) : (
          <DataTable columns={columns} data={agencies} options={options} />
        )}
      </Container>
    </Row>
  )
}

export default AgencyChangelogPage
