import React from 'react'
import AppRoutes from './routes/Routes'
import { BrowserRouter } from 'react-router-dom'
import AppWrapper from './components/AppWrapper'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AppWrapper>
        <AppRoutes />
      </AppWrapper>
    </BrowserRouter>
  )
}

export default App
