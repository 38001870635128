import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Tooltip from 'react-bootstrap/Tooltip'
import InputGroup from 'react-bootstrap/InputGroup'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import { useAppDispatch, useAppSelector } from '../store'
import { fetchCurrentAgency, updateAgency } from './agencySlice'
import { fetchAgencyChangelogs } from '../changelog/changelogSlice'
import PageLoader from '../../components/ui/PageLoader'
import { AgencyInterface } from './agencyInterface'
import Config from '../../utils/config'
import { AnyObject } from '../../common/commonTypes'
import { JobTypeInterface } from '../../common/commonSlice'

const AgencyPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { currentAgency, error, loading, submissionLoading, submissionError } = useAppSelector((state) => state.agency)
  const { jobTypes } = useAppSelector((state) => state.common)
  const { agencyId } = useParams()

  // Default agency data
  const defaultAgencyData = {
    id: Number(agencyId),
    name: currentAgency?.name ?? '',
    job_count: currentAgency?.job_count ?? 0,
    monthly_budget: currentAgency?.monthly_budget ?? 0,
    total_spent: currentAgency?.total_spent ?? 0,
    min_posted_jobs: currentAgency?.min_posted_jobs ?? 0,
    chat_now_feature: currentAgency?.chat_now_feature ?? true,
    quick_application_feature: currentAgency?.quick_application_feature ?? true,
    application_feature: currentAgency?.application_feature ?? true,
    interested_flow_feature: currentAgency?.interested_flow_feature ?? true,
    enable_hot_lead_feature: currentAgency?.enable_hot_lead_feature ?? true,
    qa_skills_checklist_feature: currentAgency?.qa_skills_checklist_feature ?? false,
    upsell_quick_application: currentAgency?.upsell_quick_application ?? true,
    upsell_application: currentAgency?.upsell_application ?? true,
    upsell_candidate_interaction_frequency: currentAgency?.upsell_candidate_interaction_frequency ?? 0,
    agency_ranking: currentAgency?.agency_ranking ?? 0,
    posting_rn_jobs: currentAgency?.posting_rn_jobs ?? true,
    posting_allied_jobs: currentAgency?.posting_allied_jobs ?? true,
    posting_lpn_jobs: currentAgency?.posting_lpn_jobs ?? true,
    travel_job_type: currentAgency?.travel_job_type ?? true,
    permanent_job_type: currentAgency?.permanent_job_type ?? false,
    test_account: currentAgency?.test_account ?? false,
    trial: currentAgency?.trial ?? false,
    posting_jobs: currentAgency?.posting_jobs ?? true,
    unlimited_budget: currentAgency?.unlimited_budget ?? true,
    posting_jobs_updated_at: currentAgency?.posting_jobs_updated_at ?? null,
    allowed_to_post_jobs: currentAgency?.allowed_to_post_jobs ?? true,
    agency_status: currentAgency?.agency_status ?? '',
    subscription_status: currentAgency?.subscription_status ?? '',
    cancellation_date: currentAgency?.cancellation_date ?? null,
    created_at: currentAgency?.created_at ?? null,
    updated_at: currentAgency?.updated_at ?? null,
    deleted_at: currentAgency?.deleted_at ?? null,
    br_job_types: currentAgency?.br_job_types ?? null,
    budget_redirection: !!currentAgency?.br_job_types,
    iterable_feed: currentAgency?.iterable_feed ?? true,
    talent_feed: currentAgency?.talent_feed ?? true,
    ziprecruiter_feed: currentAgency?.ziprecruiter_feed ?? true,
  }

  // Local states
  const [editForm, setEditForm] = useState(false)
  const [agencyState, setAgencyState] = useState<AgencyInterface | null>(currentAgency || defaultAgencyData)
  const [jobTypeOptions, setJobTypeOptions] = useState<AnyObject[]>([{}])
  const [submitModal, setSubmitModal] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [budgetConfig, setBudgetConfig] = useState<AnyObject[]>([])

  // Use navigate
  const navigate = useNavigate()

  // Calling API Ref
  const shouldCallAPI = useRef(true)

  // Fetch current agency
  useEffect(() => {
    // Should the API only first time it renders
    if (shouldCallAPI.current) {
      shouldCallAPI.current = false
      dispatch(fetchCurrentAgency(Number(agencyId)))
    }
  }, [dispatch, agencyId])

  useEffect(() => {
    if (jobTypes.length == 0) return
    const options = jobTypes.map((item: JobTypeInterface) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
    setJobTypeOptions(options)
  }, [jobTypes])

  // Set fetched agency as the agencyState
  useEffect(() => {
    if (currentAgency) {
      setAgencyState(currentAgency)

      if (currentAgency?.br_job_types) {
        const jobTypes =
          typeof currentAgency.br_job_types === 'string'
            ? currentAgency.br_job_types.split(',').map((o) => +o)
            : Array.isArray(currentAgency.br_job_types)
            ? currentAgency.br_job_types
            : []
        const jobTypeFiltered = jobTypeOptions
          .filter((option) => jobTypes.includes(option.value))
          .map((option) => option.value)
        setBudgetConfig(jobTypeFiltered)
      }
    }
  }, [currentAgency])

  // Handle form submission
  const handleSubmission = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    if (!editForm || !submitModal || !agencyState) return

    // cloning the agency state
    const data = { ...agencyState }

    // Update job posting updated datetime
    const updatedDate =
      data?.posting_jobs !== currentAgency?.posting_jobs
        ? new Date().toISOString().slice(0, 19).replace('T', ' ')
        : currentAgency?.posting_jobs || null

    if (data && (typeof updatedDate === 'string' || updatedDate === null)) {
      data.posting_jobs_updated_at = updatedDate
    }

    dispatch(updateAgency(Number(agencyId), { ...data, br_job_types: budgetConfig }))

    setFormSubmitted(true)
  }

  if (formSubmitted && submitModal && !submissionLoading && !submissionError) {
    setSubmitModal(false)
    setEditForm(false)

    setTimeout(() => {
      // Re-fetch the changelogs
      dispatch(fetchAgencyChangelogs(true))

      // Navigate to "/changelogs/agency" after the component has finished rendering
      navigate('/changelogs/agency')
    }, 0)
  }

  if (error) {
    // TODO: Handle errors
    if (Config.ENV === 'development') {
      console.error(error)
    }
  }

  return (
    <React.Fragment>
      {loading ? (
        <PageLoader position="left" loadingText="Loading agency data.." />
      ) : (
        // <div>
        //   <pre>{JSON.stringify(currentAgency, null, 2)}</pre>
        // </div>

        <React.Fragment>
          <Navbar className="bg-body-tertiary">
            <Container>
              <Nav className="me-auto">
                <Nav.Link>{agencyState?.name}</Nav.Link>
              </Nav>
              <Nav>
                <Navbar.Collapse className="d-flex">
                  <Nav.Link href={`/agency/${agencyId}/assignment_rules`}>Assignment Rules</Nav.Link>
                  <Nav.Link href={`/agency/${agencyId}/interactions`}>Interactions</Nav.Link>
                  <Nav.Link href={`/agency/${agencyId}/billing-reports`}>Billing Reports</Nav.Link>
                  <Nav.Link href={`/agency/${agencyId}/billing`}>Billing</Nav.Link>
                  <Navbar.Text>
                    <div className="d-flex justify-content-end">
                      {editForm ? (
                        <>
                          <Button
                            variant="success"
                            className="edit-button"
                            onClick={() => {
                              setSubmitModal(true)
                              setFormSubmitted(false)
                            }}
                            disabled={
                              JSON.stringify(currentAgency) === JSON.stringify(agencyState) || agencyState?.name === ''
                            }
                          >
                            Save
                          </Button>
                          <Button
                            className="edit-button"
                            variant="secondary"
                            onClick={() => {
                              setEditForm(false)
                              setAgencyState(currentAgency)
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="success"
                          className="edit-button"
                          onClick={() => {
                            setEditForm(true)
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  </Navbar.Text>
                </Navbar.Collapse>
              </Nav>
            </Container>
          </Navbar>
          <Form className={`agency-form ${editForm ? 'edit-form' : 'static-form'}`} onSubmit={handleSubmission}>
            <div className={'row'}>
              <section className={'col-sm-12 col-md-6 col-lg-4'}>
                <div className="d-flex justify-content-center align-items-center">
                  <Card className="agency-card">
                    <Card.Header as="h5">Agency Details</Card.Header>
                    <Card.Body>
                      <Card.Title>
                        <Form.Group as={Col}>
                          <div className="d-flex justify-content-between align-items-center">
                            <Form.Control
                              id="agencyNameInput"
                              type="text"
                              value={agencyState?.name}
                              placeholder="Agency Name"
                              onChange={(e) => {
                                editForm && agencyState && setAgencyState({ ...agencyState, name: e.target.value })
                              }}
                              readOnly={!editForm}
                              disabled={!editForm}
                              required
                            />
                          </div>
                        </Form.Group>
                      </Card.Title>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="trialSwitch">On Trial</Form.Label>
                          <Form.Switch
                            id="trialSwitch"
                            checked={agencyState?.trial}
                            onChange={(e) => {
                              editForm && agencyState && setAgencyState({ ...agencyState, trial: e.target.checked })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="activateJobsSwitch">
                            <span>Jobs Posted</span>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="jobs_posted_tooltip">
                                  This action may take upto 8 hours to complete
                                </Tooltip>
                              }
                            >
                              <span className="jobs-posted-tooltip-icon">
                                <i className="fa fa-circle-info"></i>
                              </span>
                            </OverlayTrigger>
                          </Form.Label>
                          <Form.Switch
                            id="activateJobsSwitch"
                            checked={agencyState?.posting_jobs}
                            onChange={(e) => {
                              editForm &&
                                agencyState &&
                                setAgencyState({
                                  ...agencyState,
                                  posting_jobs: e.target.checked,
                                })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="unlimited_budget">Enable Unlimited Budget</Form.Label>
                          <Form.Switch
                            id="unlimited_budget"
                            checked={agencyState?.unlimited_budget}
                            onChange={(e) => {
                              editForm &&
                                agencyState &&
                                setAgencyState({
                                  ...agencyState,
                                  unlimited_budget: e.target.checked,
                                })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="monthlyBudgetInput">
                            <span>Monthly Budget</span>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="jobs_posted_tooltip">
                                  Monthly Budget should be greater than Total Spent (${agencyState?.total_spent})
                                </Tooltip>
                              }
                            >
                              <span className="jobs-posted-tooltip-icon">
                                <i className="fa fa-circle-info"></i>
                              </span>
                            </OverlayTrigger>
                          </Form.Label>
                          <InputGroup>
                            <Button
                              onClick={() =>
                                editForm &&
                                agencyState &&
                                setAgencyState({
                                  ...agencyState,
                                  monthly_budget: Math.max(0, agencyState.monthly_budget - 100),
                                })
                              }
                              disabled={!editForm || agencyState?.monthly_budget == 0}
                            >
                              -
                            </Button>
                            <Form.Control
                              type="number"
                              id="monthlyBudgetInput"
                              min="0"
                              step="100"
                              value={agencyState?.monthly_budget}
                              onChange={(e) => {
                                editForm &&
                                  agencyState &&
                                  setAgencyState({ ...agencyState, monthly_budget: Number(e.target.value) })
                              }}
                              readOnly={!editForm}
                              disabled={!editForm}
                            />
                            <Button
                              onClick={() =>
                                editForm &&
                                agencyState &&
                                setAgencyState({ ...agencyState, monthly_budget: agencyState.monthly_budget + 100 })
                              }
                              disabled={!editForm}
                            >
                              +
                            </Button>
                          </InputGroup>
                        </div>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="totalSpentInput">Total Spent</Form.Label>
                          <InputGroup style={{ width: 97, height: 33 }}>
                            <Form.Control
                              type="number"
                              id="totalSpentInput"
                              value={agencyState?.total_spent}
                              readOnly
                              disabled
                            />
                          </InputGroup>
                        </div>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="agencyRankingInput">Agency Ranking</Form.Label>
                          <InputGroup>
                            <Button
                              onClick={() =>
                                editForm &&
                                agencyState &&
                                setAgencyState({
                                  ...agencyState,
                                  agency_ranking: Math.max(1, agencyState.agency_ranking - 1),
                                })
                              }
                              disabled={!editForm || agencyState?.agency_ranking == 1}
                            >
                              -
                            </Button>
                            <Form.Control
                              type="number"
                              id="agencyRankingInput"
                              value={agencyState?.agency_ranking}
                              min="1"
                              onChange={(e) => {
                                editForm &&
                                  agencyState &&
                                  setAgencyState({ ...agencyState, agency_ranking: Number(e.target.value) })
                              }}
                              readOnly={!editForm}
                              disabled={!editForm}
                            />
                            <Button
                              onClick={() =>
                                editForm &&
                                agencyState &&
                                setAgencyState({ ...agencyState, agency_ranking: agencyState.agency_ranking + 1 })
                              }
                              disabled={!editForm}
                            >
                              +
                            </Button>
                          </InputGroup>
                        </div>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="min-posted-jobs">Minimum Jobs Limit</Form.Label>
                          <InputGroup>
                            <Button
                              onClick={() =>
                                editForm &&
                                agencyState &&
                                setAgencyState({
                                  ...agencyState,
                                  min_posted_jobs: Math.max(1, agencyState.min_posted_jobs - 1),
                                })
                              }
                              disabled={!editForm || agencyState?.min_posted_jobs == 0}
                            >
                              -
                            </Button>
                            <Form.Control
                              type="number"
                              id="min-posted-jobs"
                              value={agencyState?.min_posted_jobs}
                              min="0"
                              step="1"
                              onChange={(e) => {
                                editForm &&
                                  agencyState &&
                                  setAgencyState({ ...agencyState, min_posted_jobs: Number(e.target.value) })
                              }}
                              readOnly={!editForm}
                              disabled={!editForm}
                            />
                            <Button
                              onClick={() =>
                                editForm &&
                                agencyState &&
                                setAgencyState({
                                  ...agencyState,
                                  min_posted_jobs: agencyState.min_posted_jobs ? agencyState.min_posted_jobs + 1 : 1,
                                })
                              }
                              disabled={!editForm}
                            >
                              +
                            </Button>
                          </InputGroup>
                        </div>
                      </Form.Group>
                      {/* <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="testAccountSwitch">Test Account</Form.Label>
                          <Form.Switch
                            id="testAccountSwitch"
                            checked={agencyState?.test_account}
                            onChange={(e) => {
                              editForm && agencyState && setAgencyState({ ...agencyState, test_account: e.target.checked })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group> */}
                    </Card.Body>
                  </Card>
                </div>
              </section>
              <section className={'col-sm-12 col-md-6 col-lg-4'}>
                <div className="d-flex justify-content-center align-items-center">
                  <Card className="agency-card">
                    <Card.Header as="h5">Features</Card.Header>
                    <Card.Body>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="chatAnonymouslySwitch" className="ml-auto">
                            Chat Anonymously
                          </Form.Label>
                          <Form.Switch
                            id="chatAnonymouslySwitch"
                            checked={agencyState?.chat_now_feature}
                            onChange={(e) => {
                              editForm &&
                                agencyState &&
                                setAgencyState({ ...agencyState, chat_now_feature: e.target.checked })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group>
                      {/* <Form.Group as={Col}>
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label htmlFor="qaSkillsChecklistSwitch" className="ml-auto">
                          Quick Apply Skills Checklist
                        </Form.Label>
                        <Form.Switch
                          id="qaSkillsChecklistSwitch"
                          checked={agencyState?.qa_skills_checklist_feature}
                          onChange={(e) => {
                            editForm &&
                              agencyState &&
                              setAgencyState({ ...agencyState, qa_skills_checklist_feature: e.target.checked })
                          }}
                          disabled={!editForm}
                        />
                      </div>
                    </Form.Group> */}
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="quickApplicationSwitch" className="ml-auto">
                            Quick Application
                          </Form.Label>
                          <Form.Switch
                            id="quickApplicationSwitch"
                            checked={agencyState?.quick_application_feature}
                            onChange={(e) => {
                              editForm &&
                                agencyState &&
                                setAgencyState({ ...agencyState, quick_application_feature: e.target.checked })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="applicationSwitch" className="ml-auto">
                            Full Application
                          </Form.Label>
                          <Form.Switch
                            id="applicationSwitch"
                            checked={agencyState?.application_feature}
                            onChange={(e) => {
                              editForm &&
                                agencyState &&
                                setAgencyState({ ...agencyState, application_feature: e.target.checked })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="interestedFlowSwitch" className="ml-auto">
                            Interested Flow
                          </Form.Label>
                          <Form.Switch
                            id="interestedFlowSwitch"
                            checked={agencyState?.interested_flow_feature}
                            onChange={(e) => {
                              editForm &&
                                agencyState &&
                                setAgencyState({ ...agencyState, interested_flow_feature: e.target.checked })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="hotLeadSwitch" className="ml-auto">
                            Hot Lead
                          </Form.Label>
                          <Form.Switch
                            id="hotLeadSwitch"
                            checked={agencyState?.enable_hot_lead_feature}
                            onChange={(e) => {
                              editForm &&
                                agencyState &&
                                setAgencyState({ ...agencyState, enable_hot_lead_feature: e.target.checked })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="upsellQuickAppSwitch">Upsell Quick Application</Form.Label>
                          <Form.Switch
                            id="upsellQuickAppSwitch"
                            checked={agencyState?.upsell_quick_application}
                            onChange={(e) => {
                              editForm &&
                                agencyState &&
                                setAgencyState({ ...agencyState, upsell_quick_application: e.target.checked })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="upsellApplicationSwitch">Upsell Full Application</Form.Label>
                          <Form.Switch
                            id="upsellApplicationSwitch"
                            checked={agencyState?.upsell_application}
                            onChange={(e) => {
                              editForm &&
                                agencyState &&
                                setAgencyState({ ...agencyState, upsell_application: e.target.checked })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="upsellCandidateInteractionFrequency">
                            <span>Upsell CIF</span>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="cif_tooltip">
                                  This is the number of interactions that a candidate can have with the agency per
                                  billing period via Upsell. Candidate Interactions Frequency
                                </Tooltip>
                              }
                            >
                              <span className="jobs-posted-tooltip-icon">
                                <i className="fa fa-circle-info"></i>
                              </span>
                            </OverlayTrigger>
                          </Form.Label>
                          <InputGroup>
                            <Button
                              onClick={() =>
                                editForm &&
                                agencyState &&
                                setAgencyState({
                                  ...agencyState,
                                  upsell_candidate_interaction_frequency: Math.max(
                                    0,
                                    agencyState.upsell_candidate_interaction_frequency - 1
                                  ),
                                })
                              }
                              disabled={!editForm}
                            >
                              -
                            </Button>
                            <Form.Control
                              type="number"
                              id="upsellCandidateInteractionFrequencyInput"
                              min="0"
                              step="100"
                              value={agencyState?.upsell_candidate_interaction_frequency}
                              onChange={(e) => {
                                editForm &&
                                  agencyState &&
                                  setAgencyState({
                                    ...agencyState,
                                    upsell_candidate_interaction_frequency: Number(e.target.value),
                                  })
                              }}
                              readOnly={!editForm}
                              disabled={!editForm}
                            />
                            <Button
                              onClick={() =>
                                editForm &&
                                agencyState &&
                                setAgencyState({
                                  ...agencyState,
                                  upsell_candidate_interaction_frequency:
                                    agencyState.upsell_candidate_interaction_frequency + 1,
                                })
                              }
                              disabled={!editForm}
                            >
                              +
                            </Button>
                          </InputGroup>
                        </div>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="permanentJobsSwitch">Permanent Jobs</Form.Label>
                          <Form.Switch
                            id="permanentJobsSwitch"
                            checked={agencyState?.permanent_job_type}
                            onChange={(e) => {
                              editForm &&
                                agencyState &&
                                setAgencyState({
                                  ...agencyState,
                                  permanent_job_type: e.target.checked,
                                })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group>
                      <hr className="border border-primary border-1 opacity-75" />
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="budgetRedirectionSwitch">
                            <span>Budget Redirection Config</span>
                            <OverlayTrigger
                              overlay={<Tooltip id="jp_tooltip">We only support Travel Jobs for now.</Tooltip>}
                            >
                              <span className="jobs-posted-tooltip-icon">
                                <i className="fa fa-circle-info"></i>
                              </span>
                            </OverlayTrigger>
                          </Form.Label>
                          <Form.Switch
                            id="budgetRedirectionSwitch"
                            checked={agencyState?.budget_redirection}
                            onChange={(e) => {
                              editForm &&
                                agencyState &&
                                setAgencyState({
                                  ...agencyState,
                                  budget_redirection: e.target.checked,
                                })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </div>
              </section>
              <section className={'col-sm-12 col-md-6 col-lg-4'}>
                <div className="d-flex justify-content-center align-items-center">
                  <Card className="agency-card">
                    <Card.Header as="h5">Marketing Job Feeds</Card.Header>
                    <Card.Body>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="iterableFeedSwitch" className="ml-auto">
                            Iterable
                          </Form.Label>
                          <Form.Switch
                            id="iterableFeedSwitch"
                            checked={agencyState?.iterable_feed}
                            onChange={(e) => {
                              editForm &&
                                agencyState &&
                                setAgencyState({ ...agencyState, iterable_feed: e.target.checked })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="talentFeedSwitch" className="ml-auto">
                            Talent
                          </Form.Label>
                          <Form.Switch
                            id="talentFeedSwitch"
                            checked={agencyState?.talent_feed}
                            onChange={(e) => {
                              editForm &&
                                agencyState &&
                                setAgencyState({ ...agencyState, talent_feed: e.target.checked })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label htmlFor="ziprecruiterFeedSwitch" className="ml-auto">
                            Zip Recruiter
                          </Form.Label>
                          <Form.Switch
                            id="ziprecruiterFeedSwitch"
                            checked={agencyState?.ziprecruiter_feed}
                            onChange={(e) => {
                              editForm &&
                                agencyState &&
                                setAgencyState({ ...agencyState, ziprecruiter_feed: e.target.checked })
                            }}
                            disabled={!editForm}
                          />
                        </div>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </div>
              </section>
            </div>
          </Form>

          <Modal
            show={submitModal}
            onHide={() => {
              setSubmitModal(false)
              setEditForm(false)
            }}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton={!submissionLoading}>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {formSubmitted ? (
                submissionLoading ? (
                  <PageLoader pageHeight="auto" loadingText="Saving agency data.." />
                ) : submissionError ? (
                  <Alert variant={submissionError ? 'danger' : 'success'}>
                    {submissionError || 'Data saved successfully'}
                  </Alert>
                ) : null
              ) : (
                'This action cannot be undone. Please review your inputs before saving.'
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-content-center">
                {!formSubmitted && (
                  <Button
                    variant="success"
                    className="w-50"
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      handleSubmission(e)
                    }}
                  >
                    Yes, save
                  </Button>
                )}
                <Button
                  variant="secondary"
                  className="w-50"
                  onClick={() => {
                    setSubmitModal(false)
                    !submissionError && setEditForm(false)
                  }}
                  disabled={submissionLoading}
                >
                  Close
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default AgencyPage
