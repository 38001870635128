import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

import { useAppDispatch, useAppSelector } from '../store'
import { fetchAgencies } from './agencySlice'
import PageLoader from '../../components/ui/PageLoader'
import DataTable, { TableColumn, TableOptions } from '../../components/ui/Table'
import Config from '../../utils/config'

const AgencyListPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { agencies, loading, error } = useAppSelector((state) => state.agency)

  // Calling API Ref
  const shouldCallAPI = useRef(true)

  // Fetch agencies
  useEffect(() => {
    // Should the API only first time it renders
    if (shouldCallAPI.current) {
      shouldCallAPI.current = false
      dispatch(fetchAgencies())
    }
  }, [dispatch])

  // Table columns
  const columns: TableColumn[] = [
    {
      key: 'name',
      name: 'Name',
      align: 'left',
      formatter: (value, row) => {
        if (!value || typeof value !== 'string') return null
        return row ? <Link to={row.id.toString()}>{value}</Link> : value
      },
    },
    {
      key: 'id',
      name: 'ID',
    },
    {
      key: 'agency_status',
      name: 'Status',
      formatter: (value, row) => {
        if (!value || typeof value !== 'string') return null
        const hasDate =
          value.toString().toLowerCase().includes('delete') && row?.deleted_at
            ? new Date(row?.deleted_at + 'Z').toLocaleString()
            : value.toString().toLowerCase().includes('cancel') && row?.cancellation_date
            ? new Date(row?.cancellation_date + 'Z').toLocaleString()
            : ''
        return (
          <span>
            {value}
            {hasDate && <br />}
            {hasDate}
          </span>
        )
      },
    },
    {
      key: 'allowed_to_post_jobs',
      name: 'Jobs Posted',
      booleanFilter: true,
    },
    {
      key: 'job_count',
      name: 'Job Count',
    },
    {
      key: 'monthly_budget',
      name: 'Budget',
      dataAlign: 'right',
      formatter: (value) => {
        if (!value) return null
        return Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })
      },
    },
    {
      key: 'remaining_budget',
      name: 'Remaining Budget',
      dataAlign: 'right',
      formatter: (value) => {
        if (!value) return null
        return Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })
      },
    },
    {
      key: 'total_spent',
      name: 'Total Spent',
      dataAlign: 'right',
      formatter: (value) => {
        if (!value) return null
        return Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })
      },
    },
    {
      key: 'chat_now_feature',
      name: 'Chat Anonymously',
      booleanFilter: true,
    },
    {
      key: 'quick_application_feature',
      name: 'Quick App',
      booleanFilter: true,
    },
    {
      key: 'application_feature',
      name: 'Full App',
      booleanFilter: true,
    },
    {
      key: 'interested_flow_feature',
      name: 'Interested Flow',
      booleanFilter: true,
    },
    {
      key: 'enable_hot_lead_feature',
      name: 'Hot Lead Feature',
      booleanFilter: true,
    },
    {
      key: 'upsell_quick_application',
      name: 'Upsell Quick App',
      booleanFilter: true,
    },
    {
      key: 'upsell_application',
      name: 'Upsell Full App',
      booleanFilter: true,
    },
    {
      key: 'agency_ranking',
      name: 'Search Ranking',
    },
    {
      key: 'posting_rn_jobs',
      name: 'Travel RN Jobs',
      booleanFilter: true,
    },
    {
      key: 'posting_allied_jobs',
      name: 'Travel Allied Jobs',
      booleanFilter: true,
    },
    {
      key: 'posting_lpn_jobs',
      name: 'Travel LPN Jobs',
      booleanFilter: true,
    },
    {
      key: 'travel_job_type',
      name: 'Travel Jobs',
      booleanFilter: true,
    },
    {
      key: 'permanent_job_type',
      name: 'Permanent Jobs',
      booleanFilter: true,
    },
    {
      key: 'test_account',
      name: 'Test Account',
      booleanFilter: true,
    },
    {
      key: 'trial',
      name: 'On Trial',
      booleanFilter: true,
    },
    {
      key: 'posting_jobs',
      name: 'Posting Job Flag',
      booleanFilter: true,
    },
    {
      key: 'subscription_status',
      name: 'Subscription Status',
    },
    {
      key: 'created_at',
      name: 'Created At',
      formatter: (value) => {
        if (!value || typeof value !== 'string') return null
        const date = new Date(value)
        return date.toLocaleString()
      },
    },
    {
      key: 'updated_at',
      name: 'Updated At',
      formatter: (value) => {
        if (!value || typeof value !== 'string') return null
        const date = new Date(value)
        return date.toLocaleString()
      },
    },
    {
      key: 'deleted_at',
      name: 'Deleted At',
      formatter: (value) => {
        if (!value || typeof value !== 'string') return null
        const date = new Date(value)
        return date.toLocaleString()
      },
    },
  ]

  const options: TableOptions = {
    title: 'Agencies',
    searchPlaceholder: 'Search agency..',
    searchableColumns: ['name'],
    hasBooleanFilter: true,
    checkFilters: [
      {
        key: 'agency_status',
        items: [
          {
            name: 'Active',
            match: 'active',
            checked: true,
          },
          {
            name: 'Trial',
            match: 'trial',
            checked: true,
          },
          {
            name: 'Disabled',
            match: 'disabled',
            checked: false,
          },
          {
            name: 'Cancelled',
            match: 'cancelled',
            checked: false,
          },
          {
            name: 'Deleted',
            match: 'deleted',
            checked: false,
          },
        ],
      },
    ],
    ignoreColumns: [
      // 'posting_rn_jobs',
      // 'posting_allied_jobs',
      // 'posting_lpn_jobs',
      'travel_job_type',
      'posting_jobs',
      // 'allowed_to_post_jobs',
      'test_account',
      'trial',
      // 'agency_status',
      'subscription_status',
      'cancellation_date',
      'created_at',
      'updated_at',
      'deleted_at',
    ],
  }

  if (error) {
    // TODO: Handle errors
    if (Config.ENV === 'development') {
      console.error(error)
    }
  }

  return (
    <Row>
      <Container fluid>
        {loading ? (
          <PageLoader position="left" loadingText="Loading agencies.." />
        ) : (
          <DataTable columns={columns} data={agencies} options={options} />
        )}
      </Container>
    </Row>
  )
}

export default AgencyListPage
