import React, { useState } from 'react'

import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { fetchDisableCandidate } from './disableCandidateSlice'
import { useAppDispatch, useAppSelector } from '../store'
import DisableCandidateForm from './DisableCandidateForm'
import {DisableCandidateState} from "./interfaces";

const DisableCandidatePage = () => {
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState('');
  const selector = useAppSelector((state: { disableCandidate: DisableCandidateState }) => state.disableCandidate);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (email) {
      dispatch(fetchDisableCandidate(email));
      setEmail('');
    }
  }

  return (
    <>
      <Container className={`d-flex align-items-center justify-content-center`}>
        <div className="col-6">
          <div className={`content-box`}>
            <h3>Disable Candidate</h3>
            {selector?.message && <Alert variant='success'>
              {selector?.message}
            </Alert>}

            {selector?.error && <Alert variant='danger'>
              {selector?.error}
            </Alert>}

            <Form className="d-grid" onSubmit={onSubmit}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Control
                  type="email"
                  placeholder="Enter candidate email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <div className="col-6 justify-content-center">
                <Button variant="primary" type="submit">
                  Disable
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Container>
      <DisableCandidateForm/>
    </>
  )
}

export default DisableCandidatePage
