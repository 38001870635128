import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import Form from 'react-bootstrap/Form'
import Select, { SingleValue } from 'react-select'

import Col from 'react-bootstrap/Col'
import { useAppDispatch, useAppSelector } from '../../../store'
import { RecruiterInterface } from '../recruiterInterface'
import {
  fetchProfessions,
  fetchStates,
  ProfessionInterface,
  StateInterface,
  JobTypeInterface,
} from '../../../../common/commonSlice'
import FacilitiesSelect from './FacilitiesSelect'
import SpecialtiesSelect from './SpecialtiesSelect'
import PageLoader from '../../../../components/ui/PageLoader'
import { saveAgencyAssignmentRule } from '../assignmentRulesSlice'
import CitiesSelect from './CitiesSelect'

import { AnyObject } from '../../../../common/commonTypes'

interface AssignmentRuleFormProps {
  agencyId: number
  selectedRule?: AnyObject | null
}

export interface RuleFormRef {
  submitForm: () => void
}

const AssignmentRuleForm: React.ForwardRefRenderFunction<RuleFormRef, AssignmentRuleFormProps> = (props, ref) => {
  const dispatch = useAppDispatch()
  const { savingRule, recruiters } = useAppSelector((state) => state.assignmentRules)
  const { professions, states, jobTypes } = useAppSelector((state) => state.common)

  const [recruiterOptions, setRecruiterOptions] = useState<AnyObject[]>([{}])
  const [professionOptions, setProfessionOptions] = useState<AnyObject[]>([{}])
  const [stateOptions, setStateOptions] = useState<AnyObject[]>([{}])
  const [jobTypeOptions, setJobTypeOptions] = useState<AnyObject[]>([{}])
  const [selectedJobTypeId, setSelectedJobTypeId] = useState<number | undefined>(undefined)

  // updateRule Ref
  const updateRule = useRef(false)
  const ruleId = useRef<number | undefined>(undefined)

  const emptyRule = useMemo(
    () => ({
      recruiters: [],
      professions: [],
      specialties: [],
      facilities: [],
      states: [],
      cities: [],
      jobProperties: [],
    }),
    []
  )

  const [rule, setRule] = useState<AnyObject>(emptyRule)

  const handleSubmission = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
  }

  useEffect(() => {
    dispatch(fetchProfessions())
    dispatch(fetchStates())
  }, [dispatch])

  useEffect(() => {
    if (recruiters.length == 0) return
    const options = recruiters.map((recruiter: RecruiterInterface) => {
      const recruiter_name = recruiter.full_name ? recruiter.full_name : 'Unknown'
      return {
        value: recruiter.id,
        label: `${recruiter_name} <${recruiter.email}>`,
      }
    })
    setRecruiterOptions(options)
  }, [recruiters])

  useEffect(() => {
    if (professions.length == 0) return
    const options = professions.map((item: ProfessionInterface) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
    setProfessionOptions(options)
  }, [professions])

  useEffect(() => {
    if (states.length == 0) return
    const options = states.map((item: StateInterface) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
    setStateOptions(options)
  }, [states])

  useEffect(() => {
    if (jobTypes.length == 0) return
    const options = jobTypes.map((item: JobTypeInterface) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
    setJobTypeOptions(options)
  }, [jobTypes])

  const setJobType = (data: SingleValue<AnyObject> | null) => {
    if (data) {
      const properties = rule.jobProperties.filter((property: AnyObject) => property.property !== 'job_type')
      const updatedProperties = properties.concat([
        {
          property: 'job_type',
          name: data.value,
        },
      ])
      setRule({ ...rule, jobProperties: updatedProperties })
      setSelectedJobTypeId(data.value)
    }
  }

  // Set edit form
  const setEditFormData = useCallback(
    (data: AnyObject | null) => {
      if (!data) {
        setRule(emptyRule)
        return
      }

      const currentRule: AnyObject = { ...emptyRule }

      const propertyMappings: AnyObject = {
        recruiters: (item: AnyObject) => ({
          value: item.id,
          label: `${item.full_name || 'Unknown'} <${item.email}>`,
        }),
        professions: (item: AnyObject) => ({ value: item.id, label: item.name }),
        specialties: (item: AnyObject) => ({ value: item.id, label: item.name }),
        specialities: (item: AnyObject) => ({ value: item.id, label: item.name }),
        facilities: (item: AnyObject) => ({
          value: item.id,
          label: `${item.name}, ${item.city}, ${item.state}`,
        }),
        states: (item: AnyObject) => ({ value: item.id, label: item.name }),
        cities: (item: AnyObject) => ({ value: item.id, label: item.name }),
        jobProperties: (item: AnyObject) => {
          const key = Object.keys(item)[0]
          const value = key === 'job_type' ? Number(item[key]) : item[key]
          return { property: key, name: value }
        },
      }

      Object.keys(propertyMappings).forEach((property) => {
        if (data[property]) {
          const ruleProperty = property == 'specialities' ? 'specialties' : property
          currentRule[ruleProperty] = data[property].map(propertyMappings[property])
        }
      })

      // Special case for jobProperties
      const matchedId = data.jobProperties?.filter((item: AnyObject) => 'job_type' in item)[0]?.job_type
      setSelectedJobTypeId(matchedId)

      // Set the state
      setRule(currentRule)
    },
    [setRule, emptyRule]
  )

  useEffect(() => {
    if (!props?.selectedRule) {
      ruleId.current = undefined
      updateRule.current = false
      return
    }

    if (!updateRule.current) {
      updateRule.current = true
      ruleId.current = props?.selectedRule?.id
      setEditFormData(props?.selectedRule)
    }
  }, [props?.selectedRule, setEditFormData])

  // Submit form
  const submitForm = () => {
    const format = (item: AnyObject) => {
      return { id: item.value }
    }
    const formatName = (item: AnyObject) => {
      return { name: item.label }
    }

    const payload = {
      recruiters: rule.recruiters?.map(format),
      professions: rule.professions?.map(format),
      specialties: rule.specialties?.map(format),
      facilities: rule.facilities?.map(format),
      states: rule.states?.map(format),
      cities: rule.cities?.map(formatName),
      jobProperties: rule.jobProperties,
    }
    dispatch(saveAgencyAssignmentRule(props.agencyId, payload, ruleId.current))
  }

  // Expose the `submitForm` method to the parent component
  React.useImperativeHandle(ref, () => ({
    submitForm,
  }))

  return (
    <React.Fragment>
      {savingRule ? (
        <PageLoader position="center" pageHeight="auto" />
      ) : (
        <Form className="agency-form static-form" onSubmit={handleSubmission}>
          <Form.Group as={Col}>
            <Form.Label htmlFor="recruiters">Recruiters</Form.Label>
            <Select
              id="recruiters"
              options={recruiterOptions}
              isMulti
              value={rule?.recruiters}
              onChange={(data) => setRule({ ...rule, recruiters: data })}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label htmlFor="professions">Professions</Form.Label>
            <Select
              id="professions"
              options={professionOptions}
              isMulti
              value={rule?.professions}
              onChange={(data) => setRule({ ...rule, professions: data })}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label htmlFor="facilities">Facilities</Form.Label>
            <FacilitiesSelect
              id="facilities"
              value={rule?.facilities}
              onChange={(data) => setRule({ ...rule, facilities: data })}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label htmlFor="specialties">Specialities</Form.Label>
            <SpecialtiesSelect
              id="specialties"
              value={rule?.specialties}
              onChange={(data) => setRule({ ...rule, specialties: data })}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label htmlFor="states">States</Form.Label>
            <Select
              id="states"
              options={stateOptions}
              isMulti
              value={rule?.states}
              onChange={(data) => setRule({ ...rule, states: data })}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label htmlFor="cities">Cities</Form.Label>
            <CitiesSelect id="cities" value={rule?.cities} onChange={(data) => setRule({ ...rule, cities: data })} />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label htmlFor="cities">Job Properties</Form.Label>
            <Select
              id="job_type"
              placeholder={'Job Type'}
              options={jobTypeOptions}
              value={selectedJobTypeId ? jobTypeOptions.find((option) => option.value == selectedJobTypeId) : undefined}
              onChange={(data) => setJobType(data)}
            />
          </Form.Group>
        </Form>
      )}
    </React.Fragment>
  )
}

// export default AssignmentRuleForm
export default React.forwardRef<RuleFormRef, AssignmentRuleFormProps>(AssignmentRuleForm)
