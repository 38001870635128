import React, { ReactNode, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';


import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Card, ListGroup } from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from '../../store';
import { fetchAgencyInteraction, selectAgencyInteraction } from './interactionsSlice';

const AgencyInteractionPage: React.FC = () => {
    const dispatch = useAppDispatch();

    const { agencyId=0, interactionId=0 } = useParams<{ agencyId: string, interactionId: string }>();

    const interaction = useAppSelector(selectAgencyInteraction);
    useEffect(() => {
        dispatch(fetchAgencyInteraction(+agencyId, +interactionId));
    }, [dispatch, agencyId, interactionId]);

    const treeDocument = (datum: any) : ReactNode => {
        if (!datum) {
            return ;
        }
        const v = (v: any) => {
            return v === null? '' : v;
        }
        return Object.entries(datum).map(([key, value]) => {
                    if (value === null || value === '') {
                        return null;
                    }  
                    if (typeof value === 'object') {
                        return <Card key={key} style={{ width: '22rem' }}>
                            <Card.Header as="h6">{key}</Card.Header>
                            {treeDocument(value)}
                            </Card>;
                    }
                    
                    return (
                        <ListGroup.Item key={key}>{key.replace(/([a-z](?=[A-Z]))/g, '$1 ')}: {v(value)}</ListGroup.Item>
                    );
                });
    };

    return (
        <div>
        <h1>Interaction {interaction?.type}</h1>
        <Link to={`/agency/${agencyId}/interactions`}>Back to Agency Interactions</Link>
        <Tabs
        defaultActiveKey="Interaction"
        id="uncontrolled-tab-example"
        className="mb-3"
        >
        <Tab eventKey="Interaction" title="Interaction">
        
        <Card style={{ width: '25rem' }}>
          <Card.Header as="h5">Interaction</Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item>Type: {interaction?.type}</ListGroup.Item>
            <ListGroup.Item>Tier: {interaction?.tier?.name}</ListGroup.Item>
            <ListGroup.Item>Tier Strategy: {interaction?.tier?.strategy}</ListGroup.Item>
            <ListGroup.Item>Price: {interaction?.tier?.price}</ListGroup.Item>
            <ListGroup.Item>Candidate ID: {interaction?.candidate_id}</ListGroup.Item>
            <ListGroup.Item>Created At: {`${interaction?.created_at}`}</ListGroup.Item>
            <ListGroup.Item>Updated At: {`${interaction?.updated_at}`}</ListGroup.Item>            
          </ListGroup>          
        </Card>
        </Tab>
        <Tab eventKey="shared_data" title="Shared Data">
            {interaction?.type == 'Application' && 
            interaction?.related_object?.shared_data && 
            Object.entries(interaction?.related_object?.shared_data).map(([key, value]) =>{
                if (value === null || value === '' || Object.values(value || []).length === 0) {
                    return;
                }
                return (
                    <Card key={key} style={{ width: '25rem' }}>
                    <Card.Header as="h5">{key}</Card.Header>
                    <ListGroup variant="flush">
                        {treeDocument(value)}
                    </ListGroup>          
                    </Card>
                );
            })}
            {interaction?.related_object && (
                <Card key={interaction?.id} style={{ width: '25rem' }}>
                <Card.Header as="h5">Contact</Card.Header>
                <ListGroup variant="flush">
                    {treeDocument(interaction?.related_object)}
                </ListGroup>          
                </Card>
            )}
        </Tab>        
        </Tabs>
        

        </div>
    );
};

export default AgencyInteractionPage;