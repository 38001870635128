import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form'
import { useAppDispatch, useAppSelector } from '../store';
import { fetchPlan, selectPlan, saveTier, selectPlanTiersAvailable, getTiersAvailable, deleteTier } from './plansSlice';
import { Card, Row, Col, Table, Button} from 'react-bootstrap';
/**
 * {
  "id": 2,
  "stripe_id": "plan_NBPW7W4xHvTkK7",
  "title": "Silver",
  "slug": "silver",
  "price": 1500,
  "description": "Silver Plan",
  "users": 20,
  "jobs": 400,
  "profiles": 15,
  "public": 0,
  "unlimited": 0,
  "order": 3,
  "created_at": "2018-04-03T14:59:21",
  "updated_at": "2023-01-17T00:06:33",
  "deleted_at": null,
  "interval": "day",
  "iso_currency": "usd",
  "implementation_fee": 1000,
  "is_customizable": false,
  "is_system_only": false
}
 * @returns 
 */
const PlanPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { planId=0 } = useParams<{ planId: string }>();

  const [tierSelectedId, setTierSelectedId] = React.useState(0);

  useEffect(() => {    
    dispatch(fetchPlan(+planId));
    dispatch(getTiersAvailable(+planId));
  }, [dispatch, planId]);

  const plan = useAppSelector(selectPlan);
  const tiersAvailable = useAppSelector(selectPlanTiersAvailable);

  return (
    <div>
      <h1>Plan Details</h1>
      <div>
        <Link to="/plans">Back to Plans</Link>
        <Card>
          <Card.Body>
            <Card.Title>{plan?.title}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {plan?.description}
            </Card.Subtitle>
            <Card.Text>
              <Row>
              <Col xs={12} md={6}>
                <h3>Details</h3>
                <Table>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <td>{plan?.id}</td>
                    </tr>
                    <tr>
                      <th>Title</th>
                      <td>{plan?.title}</td>
                    </tr>
                    <tr>
                      <th>Description</th>
                      <td>{plan?.description}</td>
                    </tr>
                    <tr>
                      <th>Users</th>
                      <td>{plan?.users}</td>
                    </tr>
                    <tr>
                      <th>Jobs</th>
                      <td>{plan?.jobs}</td>
                    </tr>
                    <tr>
                      <th>Profiles</th>
                      <td>{plan?.profiles}</td>
                    </tr>
                    <tr>
                      <th>Public</th>
                      <td>{plan?.public}</td>
                    </tr>
                    <tr>
                      <th>Unlimited</th>
                      <td>{plan?.unlimited}</td>
                    </tr>
                    <tr>
                      <th>Order</th>
                      <td>{plan?.order}</td>
                    </tr>
                    <tr>
                      <th>Created at</th>
                      <td>{plan?.created_at}</td>
                    </tr>
                    <tr>
                      <th>Updated at</th>
                      <td>{plan?.updated_at}</td>
                    </tr>
                    <tr>
                      <th>Deleted at</th>
                      <td>{plan?.deleted_at}</td>
                    </tr>
                    <tr>
                      <th>Interval</th>
                      <td>{plan?.interval}</td>
                    </tr>
                    <tr>
                      <th>Iso currency</th>
                      <td>{plan?.iso_currency}</td>
                    </tr>
                    <tr>
                      <th>Implementation fee</th>
                      <td>{plan?.implementation_fee}</td>
                    </tr> 
                    <tr>
                      <th>Is customizable</th>
                      <td>{plan?.is_customizable}</td>
                    </tr> 
                    <tr>
                      <th>Is system only</th>
                      <td>{plan?.is_system_only}</td>
                    </tr>
                  </thead>
                </Table>
            
              </Col>
              <Col xs={12} md={6}>
              <h3>Tiers</h3> 
              <Form>
                <Row>
                  <Col>
                  <Form.Select onChange={(e) => setTierSelectedId(+e.target.value)}>
                  <option>Select a tier</option>
                    {tiersAvailable?.map((tier) => (<option value={`${tier.id}`} key={tier.id}>{tier.name} {tier.strategy}</option>))}
                  </Form.Select>
                  </Col>
                  <Col>
                  <Button variant="primary" onClick={() => {
                    if(!plan || !plan.id) {
                      return;
                    }
                    dispatch(saveTier(+plan.id, tierSelectedId))
                  }}>Add Tier</Button>
                  </Col>
                </Row>
              </Form>
              
                <Table striped bordered hover>
                  <thead>                    
                    <tr>
                      <th>Tier</th>
                      <th>Price</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {plan?.tiers?.map((tier, index) => (
                      <tr key={index}>
                        <td>{tier.name}<br/><small>{tier.strategy}</small></td>
                        <td>${tier.price}</td>
                        <td>                          
                          <Button variant="danger" onClick={() => {
                            if(!plan ||!plan.id) {
                              return;
                            }
                            dispatch(deleteTier(plan.id, tier.id))
                          }}>Delete</Button>
                        </td>
                      </tr>                      
                    )
                  )}
                  </tbody>
                </Table>
              </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
        
      </div>

    </div>
  );
};

export default PlanPage;
