import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import apiService from '../../services/apiService'
import { AgencyChangelogInterface } from './changelogInterface'
import { AppDispatch, RootState } from '../store'

interface ChangelogState {
  agencies: AgencyChangelogInterface[]
  currentAgency: AgencyChangelogInterface[] | null
  loading: boolean
  error: string | null
}

const initialState: ChangelogState = {
  agencies: [],
  currentAgency: null,
  loading: false,
  error: null,
}

// Create slice
const changelogSlice = createSlice({
  name: 'changelog',
  initialState,
  reducers: {
    setAgencyChangelogs(state, action: PayloadAction<AgencyChangelogInterface[]>) {
      state.agencies = action.payload
      state.loading = false
      state.error = null
    },
    setCurrentAgencyChangelogs(state, action: PayloadAction<AgencyChangelogInterface[] | null>) {
      state.currentAgency = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload
      state.loading = false
    },
  },
})

export const { setAgencyChangelogs, setCurrentAgencyChangelogs, setLoading, setError } = changelogSlice.actions

// Fetch agency changelogs
export const fetchAgencyChangelogs =
  (reFetch = false) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true))

    const { agencies } = getState().changelog
    if (!reFetch && agencies.length) {
      dispatch(setLoading(false))
      return
    } else {
      try {
        apiService
          .get('/agencies/changelogs', {}, dispatch)
          .then((response) => {
            if (!response.ok) {
              dispatch(setError(response.error || 'Something went wrong'))

              return
            }

            dispatch(setAgencyChangelogs(response.data))
          })
          .catch((error) => {
            dispatch(setError(error.message || 'Something went wrong'))
          })
      } catch (error) {
        dispatch(setError((error as Error).message || 'Something went wrong'))
      }
    }
  }

export default changelogSlice.reducer
