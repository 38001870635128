import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import apiService from '../../services/apiService'
import { AgencyInterface } from './agencyInterface'
import { RecruiterInterface } from './AssignmentRules/recruiterInterface'
import { AppDispatch, RootState } from '../store'

interface AgencyState {
  agencies: AgencyInterface[]
  currentAgency: AgencyInterface | null
  assignmentRules: []
  recruiters: RecruiterInterface[]
  loading: boolean
  savingRule: boolean
  error: string | null
  submissionLoading: boolean
  submissionError: string | null
}

const initialState: AgencyState = {
  agencies: [],
  currentAgency: null,
  assignmentRules: [],
  recruiters: [],
  loading: false,
  savingRule: false,
  error: null,
  submissionLoading: false,
  submissionError: null,
}

const agencySlice = createSlice({
  name: 'agency',
  initialState,
  reducers: {
    setAgencies(state, action: PayloadAction<AgencyInterface[]>) {
      state.agencies = action.payload
      state.loading = false
      state.error = null
    },
    setCurrentAgency(state, action: PayloadAction<AgencyInterface | null>) {
      state.currentAgency = action.payload
      state.loading = false
      state.error = null
      state.submissionLoading = false
      state.submissionError = null
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload
      state.loading = false
    },
    setSubmissionLoading(state, action: PayloadAction<boolean>) {
      state.submissionLoading = action.payload
    },
    setSubmissionError(state, action: PayloadAction<string>) {
      state.submissionError = action.payload
      state.submissionLoading = false
    },
  },
})

export const { setAgencies, setCurrentAgency, setLoading, setError, setSubmissionLoading, setSubmissionError } =
  agencySlice.actions

export const fetchAgencies =
  (reload = false) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true))

    const { agencies } = getState().agency
    if (agencies.length && !reload) {
      dispatch(setLoading(false))
      return
    } else {
      try {
        apiService
          .get('/agencies/?limit=1000', {}, dispatch)
          .then((response) => {
            if (!response.ok) {
              dispatch(setError(response.error || 'Something went wrong'))

              return
            }

            dispatch(setAgencies(response.data))
          })
          .catch((error) => {
            dispatch(setError(error.message || 'Something went wrong'))
          })
      } catch (error) {
        dispatch(setError((error as Error).message || 'Something went wrong'))
      }
    }
  }

export const fetchCurrentAgency = (id: number) => (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(setLoading(true))

  const { agencies } = getState().agency
  const currentAgency = agencies.find((agency: AgencyInterface) => agency.id === id) || null

  if (currentAgency) {
    dispatch(
      setCurrentAgency({
        ...currentAgency,
        budget_redirection: !!currentAgency.br_job_types,
      })
    )
  } else {
    try {
      apiService
        .get(`/agencies/${id}`, {}, dispatch)
        .then((response) => {
          if (!response.ok) {
            dispatch(setError(response.error || 'Something went wrong'))

            return
          }
          dispatch(setCurrentAgency({ ...response, budget_redirection: !!response.br_job_types }))
        })
        .catch((error) => {
          dispatch(setError(error.message || 'Something went wrong'))
        })
    } catch (error) {
      dispatch(setError((error as Error).message || 'Something went wrong'))
    }
  }
}

export const updateAgency =
  (id: number, data: AgencyInterface | null) => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setSubmissionLoading(true))

    const { currentAgency } = getState().agency

    if (!data) {
      dispatch(setSubmissionError('No data'))
      return
    }
    if (data?.name == '') {
      dispatch(setSubmissionError('Agency name is required'))
      return
    }

    if (JSON.stringify(currentAgency) === JSON.stringify(data)) {
      dispatch(setSubmissionError('Nothing has been changed'))
      return
    }

    if (data?.budget_redirection === false) {
      data.br_job_types = null
    } else {
      const default_br_job_types = [1]
      data.br_job_types =
        typeof data.br_job_types === 'string'
          ? data.br_job_types.split(',').map((jt) => parseInt(jt, 10))
          : Array.isArray(data.br_job_types)
          ? data.br_job_types.length > 0
            ? data.br_job_types
            : default_br_job_types
          : default_br_job_types
    }

    // remove unnecessary fields
    const keysToDelete = [
      'id',
      'created_at',
      'updated_at',
      'deleted_at',
      'cancellation_date',
      'subscription_status',
      'agency_status',
      'job_count',
      'ok',
      'budget_redirection',
    ]
    // eslint-disable-next-line
    keysToDelete.forEach((key) => delete (data as any)[key])

    try {
      apiService
        .put(`/agencies/${id}/update`, JSON.stringify(data), {}, dispatch)
        .then((response) => {
          if (!response?.ok) {
            dispatch(setSubmissionError(response?.error ?? 'Something went wrong'))

            return
          }
          dispatch(
            setCurrentAgency({
              ...data,
              budget_redirection: !!data.br_job_types,
              br_job_types: data.br_job_types ? data.br_job_types.toString() : '',
            })
          )
          dispatch(fetchAgencies(true))
        })
        .catch((error) => {
          dispatch(setError(error.message || 'Something went wrong'))
        })
    } catch (error) {
      dispatch(setError((error as Error).message || 'Something went wrong'))
    }
  }

export default agencySlice.reducer
