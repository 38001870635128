import React from 'react'

const DashboardPage: React.FC = () => {
  return (
    <React.Fragment>
      <h2>Welcome to the dashboard!</h2>
    </React.Fragment>
  )
}

export default DashboardPage
