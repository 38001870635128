import React from 'react'
import AsyncSelect from 'react-select/async'

import { useAppDispatch } from '../../../store'
import { fetchCities } from '../../../../common/commonSlice'
import { AnyObject, SelectCompProps, SelectOption, LoadOptionsType } from '../../../../common/commonTypes'

const CitiesSelect: React.FC<SelectCompProps> = (props) => {
  const dispatch = useAppDispatch()

  const promiseOptions: LoadOptionsType = (inputValue: string) => {
    if (inputValue.length < 2) {
      return []
    }
    return new Promise<SelectOption[]>((resolve) => {
      dispatch(
        fetchCities(inputValue, (data) => {
          resolve(
            data.map((item: AnyObject) => {
              return {
                value: item.id,
                label: item.city,
              }
            })
          )
        })
      )
    })
  }

  let defaultOptions: SelectOption[] | SelectOption | boolean = false
  if (props?.value) {
    defaultOptions = Array.isArray(props?.value) ? props?.value : [props?.value]
  }

  return (
    <AsyncSelect
      id={props?.id}
      isMulti
      cacheOptions
      defaultOptions={defaultOptions}
      value={props?.value}
      onChange={props.onChange}
      loadOptions={promiseOptions}
    />
  )
}

export default CitiesSelect
