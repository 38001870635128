import Config from './config'

export const getToken = (): string | null => {
  const storageKey = Config.TOKEN_STORAGE_KEY ?? 'WandaAdminApp'

  try {
    const data = localStorage.getItem(storageKey)
    if (!data) return null

    const parsedData = JSON.parse(data)

    // Check if the data expired
    if (parsedData.expires && parsedData.expires < new Date().getTime()) {
      localStorage.removeItem(storageKey) // remove stored data
      return null
    }

    return parsedData.accessToken ?? null
  } catch (error) {
    if (Config.ENV === 'development') {
      console.error(error)
    }
    return null
  }
}
