
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../store'
import apiService from '../../../services/apiService';
import { Subscription }  from './interfaces';



interface SubscriptionState {
  subscriptions: Subscription[];
  loading: boolean;
  error: string | null;
}

const initialState: SubscriptionState = {
  subscriptions: [],
  loading: false,
  error: null,
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSubscriptions(state, action: PayloadAction<Subscription[]>) {
      state.subscriptions = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  },
});

export const { setSubscriptions, setLoading, setError } = subscriptionsSlice.actions;

export const fetchSubscriptions = (agency_id: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(setLoading(true));
  const { subscriptions } = getState().subscriptions;
  if (subscriptions.length) {
    dispatch(setSubscriptions(subscriptions));
    dispatch(setLoading(false));
    return;
  }
  try {
    const response = await apiService.get(`/agencies/${agency_id}/subscriptions`);
    dispatch(setSubscriptions(response.data));
    dispatch(setLoading(false));
  } catch (error : any) {
    dispatch(setError(error.message));
    dispatch(setLoading(false));
  }
};

export default subscriptionsSlice.reducer;
