import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store';
import DataTable, { TableColumn, TableOptions } from '../../../components/ui/Table';
import PageLoader from '../../../components/ui/PageLoader';
import { fetchSubscriptions } from './subscriptionsSlice';


const AgencyBillingPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { agencyId=0 } = useParams<{ agencyId: string }>();
  const subscriptions = useAppSelector((state) => state.subscriptions.subscriptions);
  const loading = useAppSelector((state) => state.subscriptions.loading);

  useEffect(() => {
    dispatch(fetchSubscriptions(+agencyId));
  }, [dispatch]);

  /**
   * {
   *             "agency_id": 261,
   *             "plan_id": 16,
   *             "stripe_id": "sub_1Ou6HmGfqM7xluMR7tJaz1Hb",
   *             "status": "active",
   *             "billing_contact": "asdfasdf",
   *             "billing_email": "adfsasdf@asdfasdf.cpo",
   *             "billing_phone": "23423423442",
   *             "created_at": "2024-03-14T04:52:58",
   *             "updated_at": "2024-03-14T04:56:40",
   *             "deleted_at": null,
   *             "id": 1
   *         }
   */
  const columns: TableColumn[] = [
    {
      key: 'id',
      name: 'ID',
      align: 'left',

    },
    {
      key: 'stripe_id',
      name: 'Stripe ID',
      align: 'left',
      formatter: (value, row) => {
        return (
          <Link to={`/agency/${agencyId}/billing/subscriptions/${row?.stripe_id}`}>{row?.stripe_id}</Link>
        );
      },
    },
    {
      key: 'billing_contact',
      name: 'Name',
      align: 'left',
    },
    {
      key: 'billing_email',
      name: 'Email',
      align: 'left',
    },
    {
      key: 'plan_id',
      name: 'Plan ID',
      align: 'right',
      formatter: (value, row) => {
        if (!value || typeof value!== 'number') return null;
        return (
          <Link target="_blank" to={`/plans/${row?.id}`}>{value}</Link>
        );
      },
    },
    {
      key: 'status',
      name: 'Status',
      align: 'left',
    },
  ];

  const options: TableOptions = {
    title: 'Subscriptions',
    searchPlaceholder: 'Search subscriptions..',
    searchableColumns: ['name', 'stripe_id' ],
    checkFilters: [
    ],
  };

  if (loading) {
    return <PageLoader position="left" loadingText="Loading subscriptions.." />;
  }
  console.log(subscriptions);

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-2xl font-bold mb-4">Subscriptions</h1>
      <Link to={`/agency/${agencyId}`}>Back to Agency</Link>
      <DataTable columns={columns} data={subscriptions} options={options} />
    </div>
  );
};

export default AgencyBillingPage;
