import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import apiService from '../services/apiService'
import { AppDispatch, RootState } from '../features/store'
import { CallbackFunc } from './commonTypes'

export interface ProfessionInterface {
  id: number
  name: string
}

export interface SpecialtyInterface {
  id: number
  name: string
}

export interface FacilityInterface {
  id: number
  name: string
  city: string
  state: string
}

export interface StateInterface {
  id: number
  name: string
}

export interface CityInterface {
  id: number
  name: string
}

export interface JobTypeInterface {
  id: number
  name: string
}

interface CommonState {
  professions: ProfessionInterface[]
  specialities: SpecialtyInterface[]
  facilities: FacilityInterface[]
  states: StateInterface[]
  cities: CityInterface[]
  jobTypes: JobTypeInterface[]
  error: string | null
}

const initialState: CommonState = {
  professions: [],
  specialities: [],
  facilities: [],
  states: [],
  cities: [],
  jobTypes: [
    { id: 1, name: 'Travel' },
    { id: 2, name: 'Permanent' },
    { id: 3, name: 'Local' },
    { id: 4, name: 'IRP' },
    { id: 5, name: 'Per Diem' },
    { id: 6, name: 'Locum Tenens' },
  ],
  error: null,
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setProfessions(state, action: PayloadAction<ProfessionInterface[]>) {
      state.professions = action.payload
    },
    setSpecialities(state, action: PayloadAction<SpecialtyInterface[]>) {
      state.specialities = action.payload
    },
    setFacilities(state, action: PayloadAction<FacilityInterface[]>) {
      state.facilities = action.payload
    },
    setStates(state, action: PayloadAction<ProfessionInterface[]>) {
      state.states = action.payload
    },
    setCities(state, action: PayloadAction<CityInterface[]>) {
      state.cities = action.payload
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload
    },
  },
})

export const { setProfessions, setSpecialities, setFacilities, setStates, setCities, setError } = commonSlice.actions

export const fetchProfessions = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const { professions } = getState().common

  // Return if already fetched
  if (professions.length) return

  try {
    const response = await apiService.get(`/professions`)
    const { data = [] } = response
    dispatch(setProfessions(data))
  } catch (error) {
    dispatch(setError((error as Error).message || 'Something went wrong'))
  }
}

export const fetchSpecialties =
  (text = 'ICU', callback: CallbackFunc = null) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await apiService.get(`/specialties?inputText=${text}`)
      const { data = [] } = response
      dispatch(setSpecialities(data))
      if (callback !== null) {
        callback(data)
      }
    } catch (error) {
      dispatch(setError((error as Error).message || 'Something went wrong'))
    }
  }

export const fetchFacilities =
  (text = 'California', callback: CallbackFunc = null) =>
  async (dispatch: AppDispatch) => {
    // TODO: Move this to an autoload while typing.
    try {
      const response = await apiService.get(`/facilities?inputText=${text}`)
      const { data = [] } = response
      if (callback !== null) {
        callback(data)
      }
      dispatch(setFacilities(data))
    } catch (error) {
      dispatch(setError((error as Error).message || 'Something went wrong'))
    }
  }

export const fetchStates =
  (text: string | null = null) =>
  async (dispatch: AppDispatch) => {
    // TODO: Move this to an autoload while typing.
    try {
      const response = await apiService.get(`/states?inputText=${text}`)
      const { data = [] } = response
      dispatch(setStates(data))
    } catch (error) {
      dispatch(setError((error as Error).message || 'Something went wrong'))
    }
  }

export const fetchCities =
  (text: string, callback: CallbackFunc = null) =>
  async (dispatch: AppDispatch) => {
    // TODO: Move this to an autoload while typing.
    try {
      const response = await apiService.get(`/cities?inputText=${text}`)
      const { data = [] } = response
      if (callback !== null) {
        callback(data)
      }
      dispatch(setCities(data))
    } catch (error) {
      dispatch(setError((error as Error).message || 'Something went wrong'))
    }
  }

export default commonSlice.reducer
