import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

type AppWrapperProps = {
  children: React.ReactNode
}

const AppWrapper: React.FC<AppWrapperProps> = ({ children }) => {
  return (
    <Container className="App" fluid>
      <Row>{children}</Row>
    </Container>
  )
}

export default AppWrapper
