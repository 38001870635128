import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Navbar from 'react-bootstrap/Navbar';
import Container from "react-bootstrap/Container";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useAppDispatch, useAppSelector } from "../store";
import PageLoader from "../../components/ui/PageLoader";

import { resetIndexer } from "./indexerSlice";

const DataIndexerIndex = () => {
  const dispatch = useAppDispatch()
  const { loading} = useAppSelector((state) => state.indexer)
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState<string | null>(null);

  const handleShow = (e: React.MouseEvent<HTMLButtonElement>) => {
    const index = e.currentTarget.id;
    setIndex(index);
    setShowModal(true);
  }
  const handleClose = () => setShowModal(false);

  const handleConfirm = () => {
    setShowModal(false);
    dispatch(resetIndexer(index));
  }

  if (loading){
    return <PageLoader pageHeight="auto" loadingText="Loading..." />
  }

  return (
    <React.Fragment>
      <Navbar className="bg-body-tertiary"  >
        <Container>
          <Nav className="me-auto">
            <Nav.Link>Data Indexer</Nav.Link>
          </Nav>
          <Nav>
            <ButtonGroup aria-label="Basic example">
              <Button variant="primary pad" disabled={true}>
                Re-index:
              </Button>
              <Button variant="primary pad" id='jobs' onClick={handleShow}>
                Jobs
              </Button>
              <Button variant="primary" id='pay_packages' onClick={handleShow}>
                Packages
              </Button>
              <Button variant="primary" id='candidates' onClick={handleShow}>
                Candidates
              </Button>
              <Button variant="primary" id='stats' onClick={handleShow}>
                Stats
              </Button>
            </ButtonGroup>
          </Nav>
        </Container>
      </Navbar>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Re-index <b>{index}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to re-index <b>{index}</b> data? This action cannot be undone.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default DataIndexerIndex;
