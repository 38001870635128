import React, { useEffect, useState } from 'react'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { useAppDispatch, useAppSelector } from '../store'
import InputGroup from 'react-bootstrap/InputGroup'
import {updateDisableCandidate} from "./disableCandidateSlice";
import { DisableCandidateState } from './interfaces';

const DisableCandidateForm = () => {
  const dispatch = useAppDispatch()
  const { data, loading } = useAppSelector((state: { disableCandidate: DisableCandidateState }) => state.disableCandidate);

  const [notification, setNotification] = useState(data?.notification);
  const [email_subscription, setEmailSubscription] = useState(data?.email_subscription);
  const [iterable, setIterable] = useState(data?.iterable);

  useEffect(() => {
    setNotification(data?.notification);
    setEmailSubscription(data?.email_subscription);
    setIterable(data?.iterable);
  }, [data]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (data && data.email) {
      const body = {
        email: data.email,
        notification: notification ?? false,
        email_subscription: email_subscription ?? false,
        iterable: iterable ?? false
      }
      dispatch(updateDisableCandidate(body))
    }
  }

  return (
    <>
      <Container className={`d-flex align-items-center justify-content-center`}>
        <div className="col-6">
          {loading &&
            <div className={`content-box`}>
              <Spinner as="span" size="sm" animation="border" role="status" />
            </div>
          }

          {(!loading && data) &&
            <Form className="d-grid" onSubmit={onSubmit}>
              <div className={`content-box`}>
                <Form.Group as={Col}>
                  <Form.Control
                    type="email"
                    value={data?.email}
                    disabled={true}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <InputGroup>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="public-switch"
                      label="Turn Off Notifications"
                      checked={notification}
                      onChange={(event) => setNotification(event.target.checked)}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col}>
                  <InputGroup>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="public-switch"
                      label="Unsubscribe from Email List"
                      checked={email_subscription}
                      onChange={(event) => setEmailSubscription(event.target.checked)}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col}>
                  <InputGroup>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="public-switch"
                      label="Remove from Iterable"
                      checked={iterable}
                      onChange={(event) => setIterable(event.target.checked)}
                    />
                  </InputGroup>
                </Form.Group>
                <div className="col-6 justify-content-center">
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </div>
              </div>
            </Form>}
        </div>
      </Container>
    </>
  )
}

export default DisableCandidateForm
