import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../store'
import apiService from '../../services/apiService'
import { DisableCandidateState } from './interfaces';

const initialState: DisableCandidateState = {
  data: null,
  loading: false,
  error: null,
  message: '',
};

export const disableCandidateSlice = createSlice({
  name: 'disableCandidate',
  initialState,
  reducers: {
    setDisableCandidate(state, action: PayloadAction<DisableCandidateState['data']>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    }
  },
});

export const { setDisableCandidate, setLoading, setError, setMessage } = disableCandidateSlice.actions;

export const fetchDisableCandidate = (email: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  try {
    const body = {email: email};
    const response = await apiService.post(`/candidates/disable_candidate_status`, JSON.stringify(body), {}, dispatch);

    if (!response?.data) {
      dispatch(setDisableCandidate(null))
      dispatch(setError('Candidate not found'));
    } else {
      dispatch(setDisableCandidate(response?.data))
    }

    dispatch(setLoading(false));

  } catch (error) {
    dispatch(setError((error as Error).message || 'Something went wrong'));
  }
  dispatch(setLoading(false));
};

export const updateDisableCandidate = (body: DisableCandidateState['data']) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await apiService.put(`/candidates/disable_candidate_status`, JSON.stringify(body));
    if (response?.notifications_service?.success) {
      dispatch(setMessage("Data saved successfully"));
    }
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setError((error as Error).message || 'Something went wrong'));
  }
  dispatch(setLoading(false));
};


export default disableCandidateSlice.reducer;
