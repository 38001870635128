import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import authSlice from './auth/authSlice'
import agencySlice from './agency/agencySlice'
import plansSlice from './billing/plansSlice'
import priceTierSlice from './billing/priceTierSlice'
import commonSlice from '../common/commonSlice'
import changelogSlice from './changelog/changelogSlice'
import assignmentRulesSlice from './agency/AssignmentRules/assignmentRulesSlice'
import interactionsSlice from './agency/interactions/interactionsSlice'
import billingReportsSlice from './agency/billingReports/reportsSlice'
import subscriptionsSlice from './agency/billing/subscriptionsSlice'
import stripeSlice from './agency/billing/stripeSlice'
import dataIndexerSlice from './dataIndexer/indexerSlice'
import disableCandidateSlice from './disableCandidates/disableCandidateSlice'


const store = configureStore({
  reducer: {
    auth: authSlice,
    agency: agencySlice,
    priceTiers: priceTierSlice,
    plans: plansSlice,
    changelog: changelogSlice,
    common: commonSlice,
    assignmentRules: assignmentRulesSlice,
    agencyInteractions: interactionsSlice,
    reports: billingReportsSlice,
    subscriptions: subscriptionsSlice,
    stripe: stripeSlice,
    indexer: dataIndexerSlice,
    disableCandidate: disableCandidateSlice
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// custom hooks
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
