// authService
import Config from '../utils/config'
import apiService from './apiService'

const authService = {
  login: async (email: string, password: string, remember: boolean) => {
    try {
      return await apiService.post('/auth/login', { email, password, remember })
    } catch (error) {
      if (Config.ENV === 'development') {
        console.error('Error logging in:', error)
      }
      throw error
    }
  },

  getUser: async () => {
    try {
      return await apiService.get('/auth/me')
    } catch (error) {
      if (Config.ENV === 'development') {
        console.error('Error while fetching the authenticated user:', error)
      }
      throw error
    }
  },
}

export default authService
