import React, {useEffect, useState} from "react"
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { useAppDispatch, useAppSelector } from '../store'
import DataTable, { TableColumn, TableOptions } from '../../components/ui/Table'
import { createPlan, fetchPlans } from './plansSlice'
import PlanForm from './PlanForm'
import PageLoader from '../../components/ui/PageLoader'
import { Plan } from './interfaces'

const PlansPage: React.FC = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    const dispatch = useAppDispatch()
    const { plans, loading } = useAppSelector((state) => state.plans)

    useEffect(() => {
        dispatch(fetchPlans())
    }, [dispatch])
    const columns: TableColumn[] = [
        {
            key: 'id',
            name: 'ID',
            formatter: (value) => {
                if (!value || typeof value!== 'number') return null
                return <Link to={`/plans/${value}`}>{value}</Link>
            }
        },
        {
            key: 'title',
            name: 'Title',
            align: 'left',
            formatter: (value, row) => {
                if (!value || typeof value!=='string') return null
                return row? <Link to={row.id.toString()}>{value}</Link> : value
            },
        },
        {
            key: 'slug',
            name: 'Slug',
        },
        {
            key: 'price',
            name: 'Price',
            dataAlign: 'right',
            formatter: (value) => {
                if (!value) return null
                return Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })
            },
        },
        {
            key: 'description',
            name: 'Description',
        },
        {
            key: 'users',
            name: 'Users',
            dataAlign: 'right',
            formatter: (value) => {
                if (!value) return null
                return `${value}` 
            },
                
        },
        {
            key: 'jobs',
            name: 'Jobs',
            formatter: (value) => {
                if (!value) return null
                return `${value}` 
            },
        },
        {
            key: 'profiles',
            name: 'Profiles',
            formatter: (value) => {
                if (!value) return null
                return `${value}` 
            },
        },
        {
            key: 'public',
            name: 'Public',
            booleanFilter: true,
        },
        {
            key: 'unlimited',
            name: 'Unlimited',
            booleanFilter: true,
        },
        {
            key: 'order',
            name: 'Order',
            formatter: (value) => {
                if (!value) return null
                return `${value}` 
            },
        },
        {
            key: 'created_at',
            name: 'Created at',
            formatter: (value) => {
                if (!value || typeof value !== 'string') return null
                const date = new Date(value)
                return date.toLocaleString()
            },
        },
        {
            key: 'updated_at',
            name: 'Updated at',
            formatter: (value) => {
                if (!value || typeof value !== 'string') return null
                const date = new Date(value)
                return date.toLocaleString()
            },
        },
        {
            key: 'deleted_at',
            name: 'Deleted at',
            formatter: (value) => {
                if (!value || typeof value !== 'string') return null
                const date = new Date(value)
                return date.toLocaleString()
            },
        },
        {
            key: 'interval',
            name: 'Interval',
        },
        {
            key: 'iso_currency',
            name: 'ISO Currency',
        },
        {
            key: 'implementation_fee',
            name: 'Implementation Fee',
        },
        {
            key: 'is_customizable',
            name: 'Is Customizable',
            booleanFilter: true,
        },
        {
            key: 'is_system_only',
            name: 'Is System Only',
            booleanFilter: true,
        }
    ];
    const options: TableOptions = {
        title: 'Plans',
        searchPlaceholder: 'Search plan..',
        searchableColumns: ['name'],
        hasBooleanFilter: true,
        checkFilters: [            
        ],
        ignoreColumns: [
            'id',
            'created_at',
            'updated_at',
            'deleted_at',
        ],
    }
    const savePlan = async (plan: Plan)=> {
      dispatch(createPlan(plan))
      handleClose()
    }
    const planModal = () => {
      return (
        <>
          <Button variant="primary" onClick={handleShow}>
            Add Plan
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>New Plan</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PlanForm plan={null} onSave={savePlan}/>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );

    }
    if (loading) return <PageLoader position="center" />
    return (<React.Fragment>
        <h1>Plans Page</h1>
      {planModal()}
        <DataTable columns={columns} data={plans} options={options} />
    </React.Fragment>);
}
export default PlansPage
