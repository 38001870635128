import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import Nav from 'react-bootstrap/Nav'
import Modal from 'react-bootstrap/Modal'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'

import useAuth from '../../hooks/useAuth'
import Config from '../../utils/config'

const Sidebar: React.FC = () => {
  const { isAuthenticated, user, handleLogout } = useAuth()
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const navigate = useNavigate()

  // Redirect to login page, if not authenticated
  useEffect(() => {
    !isAuthenticated && navigate('login')
  }, [isAuthenticated, navigate])

  const logout = (event: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault()
    handleLogout()
    navigate('login')
  }

  return (
    <React.Fragment>
      <Nav defaultActiveKey="/" className="flex-column sidebar-wrapper">
        <Navbar className="text-center">
          <Navbar.Brand as={NavLink} to="/" onClick={(e) => e.preventDefault()}>
            {Config.APP_NAME}
          </Navbar.Brand>
          <div className="adminEmail text-muted">{user ? user.name ?? user.email : ''}</div>
        </Navbar>

        <div className="nav-items-wrapper">
          <Nav.Item>
            <NavLink to="/dashboard" className="nav-link">
              Dashboard
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to="/agency" className="nav-link">
              Agency List
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to="/changelogs/agency" className="nav-link">
              Agency Change Log
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to="/plans" className="nav-link">
              Plans
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to="/price-tiers" className="nav-link">
              Price Tiers
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to="/data-indexer" className="nav-link">
              Data Indexer
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to="/disable-candidates" className="nav-link">
              Disable Candidates
            </NavLink>
          </Nav.Item>
        </div>

        <div className="sidebar-bottom-wrapper">
          <Button
            onClick={() => {
              setShowLogoutModal(true)
            }}
          >
            Logout
          </Button>
        </div>
      </Nav>

      <Modal
        show={showLogoutModal}
        onHide={() => {
          setShowLogoutModal(false)
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          By logging out, you will be redirected to the login page and your session will be terminated.
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center">
            <Button
              variant="primary"
              className="w-50"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                logout(e)
              }}
            >
              Logout
            </Button>
            <Button
              variant="secondary"
              className="w-50"
              onClick={() => {
                setShowLogoutModal(false)
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default Sidebar
