import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../store';
import { Link, useParams } from 'react-router-dom';
import { RootState } from '../../store';
import PageLoader from '../../../components/ui/PageLoader';

import { fetchBillingReports } from './reportsSlice';

import DataTable, { TableColumn, TableOptions } from '../../../components/ui/Table';

const ReportsListPage: React.FC = () => {
  const dispatch = useAppDispatch();  
  const { agencyId=0 } = useParams<{ agencyId: string }>(); 
  const { reports, isLoading } = useAppSelector((state: RootState) => state.reports);

  // Table columns
  const columns: TableColumn[] = [    
    {
      key: 'name',
      name: 'Name',
      align: 'left',
      formatter: (value, _) => {
        if (!value || typeof value !== 'string') return null;
        return (          
            value
        );
      },
    },
    {
        key: 'key',
        name: 'File',
        align: 'left',
        formatter: (value, row) => {
          if (!value || typeof value !== 'string') return null;
          return (
            <Link target="_blank" to={`${row.url}`} className="text-blue-500 hover:underline">
              {value}
            </Link>
          );
        },
    } 
  ];

  const options: TableOptions = {
    title: 'Reports',
    searchPlaceholder: 'Search Reports..',
    searchableColumns: ['name'],
    hasBooleanFilter: false,
    checkFilters: [],
  };

  useEffect(() => {
    console.log('Reports:', reports);
  }, [reports]);

  useEffect(() => {
    dispatch(fetchBillingReports(+agencyId));
  }, [dispatch, agencyId]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="container mx-auto px-4">
        <Link to={`/agency/${agencyId}`}>Back to Agency</Link>
      <DataTable columns={columns} data={reports} options={options} />
    </div>
  );
};

export default ReportsListPage;