import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import { fetchPriceTiers } from './priceTierSlice';
import { Link } from 'react-router-dom';
import PageLoader from '../../components/ui/PageLoader'

import DataTable, { TableColumn, TableOptions } from '../../components/ui/Table';


const PriceTierListPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPriceTiers());
  }, [dispatch]);

  const {priceTiers, loading} = useAppSelector((state) => state.priceTiers);

  const columns: TableColumn[] = [
    {
      key: 'name',
      name: 'Name',
      align: 'left',
      formatter: (value, row) => {
        if (!value || typeof value !== 'string') return null
        return <Link to={`/price-tiers/${row.id}`}>{value}</Link>
      },
    },
    {
      key: 'description',
      name: 'Description',
    },
    {
      key: 'price',
      name: 'Price',
      dataAlign: 'right',
      formatter: (value) => {
        if (!value) return null
        return Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 })
      },
    },
    {
      key: 'public',
      name: 'Public',
      formatter(value) {
        return value? 'Yes' : 'No'
      },      
    },
    {
      key: 'strategy',
      name: 'Strategy',
    },
    {
      key: 'conditions',
      name: 'Conditions',
      formatter: (conditions, row) => {
        /**
         * "conditions": {
                "max_percentage": 50,
                "min_percentage": 0
            },
         */
        if (!conditions || typeof conditions!== 'object') return null
        if (row.strategy == 'ProfilePercentage'){
          return (
            <div>
              <div>Max Percentage: {conditions.max_percentage}</div>
              <div>Min Percentage: {conditions.min_percentage}</div>
            </div>
          )
        } else if (row.strategy == 'InteractionType') {
          return conditions.value.join(' or ');
        } else if (row.strategy == 'ProfileSections') {
          return conditions.value.join(', ');
        } else if (row.strategy == 'InteractionSections') {
          return (
            <div>
              <div>Must Have: {conditions.value?.mandatorySections?.join(' and ')}</div>
              <div>At least one: {conditions.value?.optionalSections?.join(' or ')}</div>
            </div>
          )
        }
      },
    },
    {
      key: 'agency_id',
      name: 'Agency ID',
    },
    {
      key: 'version',
      name: 'Version',
    },
    {
      key: 'created_at',
      name: 'Created at',
      formatter: (value) => {
        if (!value || typeof value !== 'string') return null
        const date = new Date(value)
        return date.toLocaleString()
      },
    },
    {
      key: 'updated_at',
      name: 'Updated at',
      formatter: (value) => {
        if (!value || typeof value !== 'string') return null
        const date = new Date(value)
        return date.toLocaleString()
      },
    },
  ];

  const options: TableOptions = {
    title: 'Price Tiers',
    searchPlaceholder: 'Search price tier..',
    searchableColumns: ['name'],
  };

  return (
    <React.Fragment>             
        <Link to="/price-tiers/new">New Price Tier</Link>
        {loading? (<PageLoader pageHeight="auto" loadingText="Loading price tiers.." />):
        (
            <React.Fragment>
                <DataTable columns={columns} data={priceTiers} options={options} />
            </React.Fragment>   
        )} 
      
    </React.Fragment>
  );
};

export default PriceTierListPage;
