import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store'
import apiService from '../../services/apiService'
import { PlansState, Plan, PriceTier } from './interfaces'



const initialState: PlansState = {
  plan: null,
  available_tiers: [],
  plans: [],
  loading: false,
  error: null,
};

const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setPlans(state, action: PayloadAction<Plan[]>) {
      state.plans = action.payload;
      state.loading = false;
      state.error = null;
    },
    setPlan: (state, action: PayloadAction<Plan>) => {
      state.plan = action.payload
      state.loading = false;
      state.error = null;
    },
    setPlanTiersAvailable: (state, action: PayloadAction<PriceTier[]>) => {
      state.available_tiers = action.payload
      state.loading = false;
      state.error = null;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },

  },
});

export const { setPlans, setPlan, setPlanTiersAvailable, setLoading, setError } = plansSlice.actions;

export const fetchPlans = (force = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(setLoading(true));

  const { plans } = getState().plans;
  if (plans.length && !force) {
    dispatch(setLoading(false));
    return;
  } else {
    try {
      const response = await apiService.get('/plans/?page=1', {}, dispatch);

      if (!response.ok) {
        dispatch(setError(response.error || 'Something went wrong'));
        return;
      }

      dispatch(setPlans(response.data));
    } catch (error) {
      dispatch(setError((error as Error).message || 'Something went wrong'));
    }
  }
};

export const fetchPlan = (id: number, force=false) => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(setLoading(true));

  const { plan } = getState().plans;
  if (plan?.id === id && !force) {
    dispatch(setLoading(false));
    return;
  } else {
    try {
      const response = await apiService.get(`/plans/${id}`, {}, dispatch);

      if (!response.ok) {
        dispatch(setError(response.error || 'Something went wrong'));
        return;
      }      
      dispatch(setPlan(response.data));
    } catch (error) {
      dispatch(setError((error as Error).message || 'Something went wrong'));
    }
  }
};

export const getTiersAvailable = (plan_id: number) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));  
  try {
    const response = await apiService.get(`/plans/${plan_id}/tiers/availability`, {}, dispatch);
    if (!response.ok) {
      dispatch(setError(response.error || 'Something went wrong'));
      return;
    }
    dispatch(setPlanTiersAvailable(response.data));
  } catch (error) {
    dispatch(setError((error as Error).message || 'Something went wrong'));
  }
};

export const deleteTier = (plan_id: number, tier_id: number | null) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  try {
    const response = await apiService.delete(`/plans/${plan_id}/tiers/${tier_id}`, {}, dispatch);

    if (!response.ok) {
      dispatch(setError(response.error || 'Something went wrong'));
      return;
    }
    dispatch(fetchPlan(plan_id, true));
    dispatch(getTiersAvailable(plan_id));
  } catch (error) {
    dispatch(setError((error as Error).message || 'Something went wrong'));
  }
};

export const saveTier = (plan_id: number, tier_id: number ) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  try{
    const response = await apiService.post(`/plans/${plan_id}/tiers/${tier_id}`, {}, {}, dispatch);
    if (!response.ok) {
      dispatch(setError(response.error || 'Something went wrong'));
      return;
    }
    dispatch(fetchPlan(plan_id, true));
    dispatch(getTiersAvailable(plan_id));
  } catch (error) {
    dispatch(setError((error as Error).message || 'Something went wrong'));  
  }
};


export const createPlan = (plan: Plan) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await apiService.post('/plans/', JSON.stringify(plan), {}, dispatch);
    if (!response.ok) {
      dispatch(setError(response.error || 'Something went wrong'));
      return;
    }
    dispatch(fetchPlans(true));
  } catch (error) {
    dispatch(setError((error as Error).message || 'Something went wrong'));
  }
};

export const selectPlan = (state: RootState) => {
  const { plan } = state.plans;
  return plan
};

export const selectPlanTiersAvailable = (state: RootState) => {
  const { available_tiers } = state.plans;
  return available_tiers
};


// Additional action creators and async operations can be added as needed

export default plansSlice.reducer;
