import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { fetchInvoices, fetchPayments, getSubscription, saveCharge, saveGoLiveDate, updatePlan } from './stripeSlice'
import { Card, Button, ListGroup, Row, Col} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'
import DataTable, { TableColumn, TableOptions } from '../../../components/ui/Table'
import Form from 'react-bootstrap/Form'
import { fetchPlans} from '../../billing/plansSlice'
import Modal from 'react-bootstrap/Modal'
import { Plan, StripePlan } from '../../billing/interfaces'

const AgencySubscriptionPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { subscriptionId = '', agencyId } = useParams<{ subscriptionId: string, agencyId: string }>();
  const { subscription, payments, invoices, chargeSaving, loading } = useSelector((state: RootState) => state.stripe);
  const [plan, setPlan] = useState<StripePlan | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const {plans} = useSelector((state: RootState) => state.plans);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch(getSubscription(subscriptionId, false));
    dispatch(fetchInvoices(subscriptionId));
    dispatch(fetchPayments(subscriptionId));
    dispatch(fetchPlans(true))
  }, [dispatch]);

  useEffect(() => {
    if(!subscription) return;
    setPlan(subscription.plan);
  }, [subscription]);

  const changePlan = () => {
    if(!selectedPlan) return;

    const plan_stripe_id = (selectedPlan as Plan).stripe_id;
    if(plan_stripe_id == subscription.plan.id) {
      return;
    }
    dispatch(updatePlan(subscriptionId, `${plan_stripe_id}`));
    handleClose();
  }

  const planModal = () => {
    return (
      <>
        <Button variant="danger" onClick={handleShow}>
          Change Plan
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Select New Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Plan</Form.Label>
                <Form.Control as="select" value={selectedPlan? (selectedPlan as Plan).id : ''} onChange={(e) => {
                  const planFound = plans.find(p => p.id === parseInt(e.target.value));
                  if(planFound) {
                    setSelectedPlan(planFound);
                  } else {
                    setSelectedPlan(null);
                  }
                }}>
                  {plans.map(p => <option key={p.id} value={p.id}>
                    {p.title}: ${(p.price).toFixed(2)} ({p.stripe_id})
                  </option>)}
                </Form.Control>
              </Form.Group>
            </Form>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="button" onClick={changePlan} disabled={loading}>Save</Button>
          </Modal.Footer>
        </Modal>
      </>
    );

  }

  const onSaveCharge = (e : React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      amount: { value: number };
      description: { value: string };
    };
    const charge = {
      amount: target.amount.value * 100,
      description: target.description.value,
      subscription_id: subscriptionId
    };
    dispatch(saveCharge(charge));
    (e.target as HTMLFormElement).reset();
  }

  const onSaveGoLiveDate = (e : React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      date: { value: any };
    };
    const goLiveDate = {
      date: target.date.value,
      subscription_id: subscriptionId
    };
    dispatch(saveGoLiveDate(goLiveDate));
    (e.target as HTMLFormElement).reset();
  }

  const canSetGoLiveDate = () => {
    if(!subscription) return false;
    const trialEnd = new Date(subscription.trial_end * 1000)
    const today = new Date()
    return today <= trialEnd
  }

  const invoiceColumns: TableColumn[] = [
    {
      key: 'id',
      name: 'ID',
    },
    {
      key: 'amount_due',
      name: 'Amount Due',
      formatter: (value) => {
        if (!value || typeof value!== 'number') return null
        return `$${value/100}`
      },
    },
    {
      key: 'amount_paid',
      name: 'Amount Paid',
      formatter: (value) => {
        if (!value || typeof value!== 'number') return null
        return `$${value/100}`
      },
    },
    {
      key: 'billing_reason',
      name: 'Billing Reason',
    },
    {
      key: 'paid',
      name: 'Paid',
      formatter: (value) => {
        return value ? 'Yes' : 'No'
      }
    },
    {
      key: 'created',
      name: 'Created',
      formatter: (value) => {
        if (!value || typeof value!=='number') return null
        const date = new Date(value * 1000)
        return date.toLocaleString()
      },
    }
  ];

  const paymentColumns: TableColumn[] = [
    {
      key: 'id',
      name: 'ID',
    },
    {
      key: 'amount',
      name: 'Amount',
      formatter: (value) => {
        if (!value || typeof value!== 'number') return null
        return `$${value/100}`
      },
      align: 'right'
    },
    {
      key: 'description',
      name: 'Description',
    },
    {
      key: 'status',
      name: 'Status',
    },
    {
      key: 'created',
      name: 'Created',
      formatter: (value) => {
        if (!value || typeof value!=='number') return null
        const date = new Date(value * 1000)
        return date.toLocaleString()
      },
    }
  ];

  const invoiceOptions: TableOptions = {
    title: 'Invoices',
  };

  const paymentOptions: TableOptions = {
    title: 'Payments',
  };

  const getCard = () => {
    // Formatear la fecha de inicio
    const startDate = new Date(subscription.created * 1000).toLocaleDateString();
    if (!subscription || subscription.items.length === 0) return null;

    // Obtener detalles adicionales
    const planName = subscription.items.data[0].plan.nickname; // Asume que hay al menos un item
    const amount = (subscription.items.data[0].plan.amount / 100).toFixed(2); // Convertir a formato de moneda
    const currency = subscription.items.data[0].plan.currency.toUpperCase();
    const billingCycle = subscription.items.data[0].plan.interval;
    const nextBillingDate = new Date(subscription.current_period_end * 1000).toLocaleString();
    const trialEnd = new Date(subscription.trial_end * 1000).toLocaleString();
    const billingCycleAnchor = new Date(subscription.billing_cycle_anchor * 1000).toLocaleString();
    const periodStart = new Date(subscription.current_period_start * 1000).toLocaleString();
    const periodEnd = new Date(subscription.current_period_end * 1000).toLocaleString();

    return (
      <Card className="my-3">
        <Card.Header as="h5">Subscription Details</Card.Header>
        <Card.Body>
          <Card.Title>{subscription.id}</Card.Title>
          <ListGroup variant="flush">
            <ListGroup.Item>Start Date: {startDate}</ListGroup.Item>
            <ListGroup.Item>Plan: {planName}</ListGroup.Item>
            <ListGroup.Item>Amount: {amount} {currency}</ListGroup.Item>
            <ListGroup.Item>Billing Cycle: {billingCycle}</ListGroup.Item>
            <ListGroup.Item>Next Billing Date: {nextBillingDate}</ListGroup.Item>
            <ListGroup.Item>Billing Cycle Anchor: {billingCycleAnchor}</ListGroup.Item>
            <ListGroup.Item>Trial End: {trialEnd}</ListGroup.Item>
            <ListGroup.Item>Period Start: {periodStart}</ListGroup.Item>
            <ListGroup.Item>Period End: {periodEnd}</ListGroup.Item>
          </ListGroup>
        </Card.Body>
        <Card.Footer className="text-muted">
          Status: {subscription.status} |
          Customer: {subscription.customer} |
        </Card.Footer>
      </Card>
    );
  }

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Subscription Details</h1>
      <Link to={`/agency/${agencyId}`}>Back to Agency</Link>
      <Row>
        <Col xs={12} md={6}>
        {subscription && getCard()}
        </Col>
        <Col xs={12} md={6}>
          <Card className="my-3">
            <Card.Header as="h5">Manual Charge</Card.Header>
            <Card.Body>
              <Form onSubmit={onSaveCharge}>
                <Form.Group >
                  <Form.Label>Amount</Form.Label>
                  <Form.Control type="number" placeholder="Amount 100.00" id="amount" disabled={chargeSaving}/>
                </Form.Group>
                <Form.Group >
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="text" placeholder="Description" id="description" disabled={chargeSaving}/>
                </Form.Group>
                <Button variant="primary" type="submit" disabled={chargeSaving}>Charge</Button>
              </Form>
            </Card.Body>
          </Card>
          { canSetGoLiveDate() &&
          <Card className="my-3">
            <Card.Header as="h5">Go Live Date</Card.Header>
            <Card.Body>
              <Form onSubmit={onSaveGoLiveDate}>
                <Form.Group >
                  <Form.Label>Date</Form.Label>
                  <Form.Control type="date" placeholder="Date" id="date" disabled={loading}/>
                </Form.Group>
                <Button variant="primary" type="submit" disabled={loading}>Save</Button>
              </Form>
            </Card.Body>
          </Card>
          }
          {plan &&
            <Card className="my-3">
              <Card.Header as="h5">Plan Details {planModal()}</Card.Header>
              <Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item>ID: {plan.id}</ListGroup.Item>
                  <ListGroup.Item>Name: {plan.nickname}</ListGroup.Item>
                  <ListGroup.Item>Amount: {(plan.amount / 100).toFixed(2)}</ListGroup.Item>
                  <ListGroup.Item>Currency: {plan.currency.toUpperCase()}</ListGroup.Item>
                  <ListGroup.Item>Interval: {plan.interval}</ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          }
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <DataTable columns={invoiceColumns} data={invoices} options={invoiceOptions} />
        </Col>
        <Col xs={12} md={6}>
          <DataTable columns={paymentColumns} data={payments} options={paymentOptions} />
        </Col>
      </Row>
    </div>
  );
};

export default AgencySubscriptionPage;
