import apiService from '../../../services/apiService';
import { PriceTier } from '../../billing/interfaces';
import { AppDispatch, RootState } from '../../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AgencyInteraction {
  id: number;
  type: string;
  type_id: number;
  agency_id: number;
  candidate_id: number;
  related_object: any;
  tier_id: number;
  tier: PriceTier | null;
  created_at: Date;
  updated_at: Date;
}

interface AgencyInteractionsState {
  interactions: AgencyInteraction[];
  interaction: AgencyInteraction | null;
  loading: boolean;
  error: string | null;
}

const initialState: AgencyInteractionsState = {
  interactions: [],
  interaction: null,
  loading: false,
  error: null,
};

const agencyInteractionsSlice = createSlice({
  name: 'agencyInteractions',
  initialState,
  reducers: {
    setAgencyInteractions(state, action: PayloadAction<AgencyInteraction[]>) {
      state.interactions = action.payload;
      state.loading = false;
    },
    setAgencyInteraction(state, action: PayloadAction<AgencyInteraction | null>) {
      state.interaction = action.payload;
      state.loading = false;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { setAgencyInteractions, setAgencyInteraction, setLoading, setError } = agencyInteractionsSlice.actions;

export const fetchAgencyInteractions = (agencyId: number) => (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(setLoading(true))

  const { interactions } = getState().agencyInteractions
  if (interactions.length && interactions[0].agency_id === agencyId) {
    dispatch(setLoading(false))
    return
  } else {
    try {
      apiService
        .get(`/agencies/${agencyId}/interactions`, {}, dispatch)
        .then((response) => {
          if (!response.ok) {
            dispatch(setError(response.error || 'Something went wrong'))

            return
          }

          dispatch(setAgencyInteractions(response.data))
        })
        .catch((error) => {
          dispatch(setError(error.message || 'Something went wrong'))
        })
    } catch (error) {
      dispatch(setError((error as Error).message || 'Something went wrong'))
    }
  }
}

export const fetchAgencyInteraction = (agencyId: number, interactionId: number) => (dispatch: AppDispatch) => {
  dispatch(setLoading(true))
  try{
    apiService
   .get(`/agencies/${agencyId}/interactions/${interactionId}`, {}, dispatch)
   .then((response) => {
        if (!response.ok) {
          dispatch(setError(response.error || 'Something went wrong'))

          return
        }

        dispatch(setAgencyInteraction(response.data))
      })
   .catch((error) => {
        dispatch(setError(error.message || 'Something went wrong'))
      })      
  } catch (error) {
    dispatch(setError((error as Error).message || 'Something went wrong'))
  }
}

export const selectAgencyInteraction = (state: RootState) => {
  const { interaction } = state.agencyInteractions;
  return interaction
};
export default agencyInteractionsSlice.reducer;