import React from 'react'

// import `react-bootstrap` components
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Sidebar from '../layout/Sidebar'

type DashLayoutProps = {
  children: React.ReactNode
}

const DashboardLayout: React.FC<DashLayoutProps> = ({ children }) => {
  return (
    <Container fluid>
      <Row>
        <Col className="adminSidebar">
          <Sidebar />
        </Col>
        <Col className="adminContent">{children}</Col>
      </Row>
    </Container>
  )
}

export default DashboardLayout
