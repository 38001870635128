import { RecruiterInterface } from './recruiterInterface'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '../../store'

import apiService from '../../../services/apiService'
import { AnyObject } from '../../../common/commonTypes'

interface AssignmentRulesStateInterface {
  rules: []
  recruiters: RecruiterInterface[]
  savingRule: boolean
  deletingRule: boolean
  error: string | null
}

const initialState: AssignmentRulesStateInterface = {
  rules: [],
  recruiters: [],
  savingRule: false,
  deletingRule: false,
  error: null,
}

const assignmentRulesSlice = createSlice({
  name: 'assignmentRules',
  initialState,
  reducers: {
    setAgencyAssignmentRules(state, action: PayloadAction<[]>) {
      state.rules = action.payload
    },
    setRecruiters(state, action: PayloadAction<[]>) {
      state.recruiters = action.payload
    },
    setSavingRule(state, action: PayloadAction<boolean>) {
      state.savingRule = action.payload
    },
    setDeleting(state, action: PayloadAction<boolean>) {
      state.deletingRule = action.payload
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload
    },
  },
})

export const { setRecruiters, setAgencyAssignmentRules, setSavingRule, setDeleting, setError } =
  assignmentRulesSlice.actions

export const fetchAgencyRecruiters = (agencyId: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await apiService.get(`/agencies/${agencyId}/recruiters`)
    const { data = [] } = response

    dispatch(setRecruiters(data))
  } catch (error) {
    dispatch(setError((error as Error).message || 'Something went wrong'))
  }
}

export const fetchAgencyAssignmentRules = (agencyId: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await apiService.getWAPI(`/admin/agencies/${agencyId}/assignment/rules`)
    const { data = [] } = response

    dispatch(setAgencyAssignmentRules(data))
  } catch (error) {
    dispatch(setError((error as Error).message || 'Something went wrong'))
  }
}

export const saveAgencyAssignmentRule =
  (agencyId: number, rule: AnyObject, ruleId: number | null | undefined = null) =>
  async (dispatch: AppDispatch) => {
    dispatch(setSavingRule(true))
    try {
      const wapiUrl = !ruleId
        ? `/admin/agencies/${agencyId}/assignment/rules`
        : `/admin/agencies/${agencyId}/assignment/rules/${ruleId}`

      const response = !ruleId ? await apiService.postWAPI(wapiUrl, rule) : await apiService.putWAPI(wapiUrl, rule)

      if (!response.ok) {
        dispatch(setError(response.error || 'Something went wrong'))
        return
      }

      dispatch(fetchAgencyAssignmentRules(agencyId))
    } catch (error) {
      dispatch(setError((error as Error).message || 'Something went wrong'))
    }
    dispatch(setSavingRule(false))
  }

export const deleteAgencyAssignmentRule = (agencyId: number, rule: AnyObject) => async (dispatch: AppDispatch) => {
  dispatch(setDeleting(true))
  try {
    const response = await apiService.deleteWAPI(`/admin/agencies/${agencyId}/assignment/rules/${rule.id}`)
    if (!response.ok) {
      dispatch(setError(response.error || 'Something went wrong'))
      return
    }

    dispatch(fetchAgencyAssignmentRules(agencyId))
  } catch (error) {
    dispatch(setError((error as Error).message || 'Something went wrong'))
  }
  dispatch(setDeleting(false))
}

export default assignmentRulesSlice.reducer
