import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthInterface, UserInterface } from './authInterface'

const initialState: AuthInterface = {
  user: null,
  isAuthenticated: false,
  hasAdminAccess: false,
  accessToken: null,
  loading: false,
  error: null,
  tokenExpired: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state) {
      state.loading = true
      state.error = null
      state.tokenExpired = false
    },
    loginSuccess(state, action: PayloadAction<{ user: UserInterface; accessToken: string }>) {
      state.loading = false
      state.isAuthenticated = true
      state.user = action.payload.user
      state.accessToken = action.payload.accessToken
    },
    loginFail(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
    logout(state) {
      state.isAuthenticated = false
      state.hasAdminAccess = false
      state.tokenExpired = false
      state.accessToken = null
      state.user = null
    },
    sessionExpire(state, action: PayloadAction<{ tokenExpired: boolean; error?: string }>) {
      state.loading = false
      state.error = action.payload.error ?? null
      state.tokenExpired = action.payload.tokenExpired
    },
  },
})

// export actions
export const { loginStart, loginSuccess, loginFail, logout, sessionExpire } = authSlice.actions

export default authSlice.reducer
