import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAppDispatch, useAppSelector } from '../features/store'
import { loginStart, loginSuccess, loginFail, logout } from '../features/auth/authSlice'
import { LoginInterface } from '../features/auth/authInterface'
import authService from '../services/authService'
import useStorage, { StorageObject } from './useStorage'
import Config from '../utils/config'

const useAuth = () => {
  const { isAuthenticated, user, loading, error, tokenExpired } = useAppSelector((state) => state.auth)
  const [pageLoading, setPageLoading] = useState(true)
  const dispatch = useAppDispatch()
  const storage = useStorage()

  // Calling API Ref
  const shouldCallAPI = useRef(true)

  // Logout method
  const handleLogout = useCallback(async () => {
    dispatch(logout())
    storage.remove()
  }, [dispatch, storage])

  // Get user from storage
  const getUser = useMemo(
    () => async () => {
      const storedData = storage.get() as StorageObject
      if (storedData?.accessToken) {
        // authService.getUser().then((response) => {
        //   console.log('/auth/me response: ', response)
        // })

        if (storedData.user) {
          dispatch(loginSuccess(storedData))
        } else {
          handleLogout()
        }
      } else {
        handleLogout()
      }
    },
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    // Should the API only first time it renders
    if (shouldCallAPI.current) {
      shouldCallAPI.current = false

      getUser().then(() => {
        setPageLoading(false)
      })
    }
  }, [getUser])

  // Destroy session data, if the token is expired
  useEffect(() => {
    if (tokenExpired) {
      const storedData = storage.get() as StorageObject
      if (storedData?.accessToken) {
        handleLogout()
      }
    }
  }, [tokenExpired, storage, handleLogout])

  return {
    isAuthenticated,
    user,
    error,
    loading,
    pageLoading,
    handleLogout,
    handleLogin: async (email: string, password: string, rememberMe: boolean) => {
      try {
        dispatch(loginStart())
        authService
          .login(email, password, rememberMe)
          .then((response) => {
            if (!response || !response.ok) {
              dispatch(loginFail(response?.error || 'Something went wrong'))
              if (Config.ENV === 'development') {
                console.error('Login attempt failed: ', response?.error || 'no response')
              }
              // return response
              return
            }

            const data: LoginInterface = {
              user: {
                id: response.id,
                name: response.name,
                email: response.email,
              },
              accessToken: response.access_token,
            }

            // store auth state
            dispatch(loginSuccess(data))

            // Set auth expiration, based om `rememberMe`
            const expires = new Date()

            if (rememberMe) {
              // set longer expiration for remembered users
              expires.setDate(expires.getDate() + Config.REMEMBERED_TOKEN_EXPIRED_DAYS)
            } else {
              // Set default expiration
              expires.setHours(expires.getHours() + Config.DEFAULT_TOKEN_EXPIRED_HOURS)
            }

            storage.set(data, expires.getTime())
            // return data
            return
          })
          .catch((error) => {
            dispatch(loginFail(error.message || 'Something went wrong'))

            if (Config.ENV === 'development') {
              console.error('Login attempt failed: ', error)
            }
          })
      } catch (error) {
        dispatch(loginFail('Something went wrong'))

        if (Config.ENV === 'development') {
          console.error('Error logging in:', error)
        }
      }
      // return false
    },
  }
}

export default useAuth
