import Config from '../utils/config'
import { getToken } from '../utils/helpers'

import { sessionExpire } from '../features/auth/authSlice'

type ApiSource = 'adminapi' | 'wapi'
type BodyPayload = Record<string, unknown> | string
type DispatchFunction = (action: SessionExpireAction) => void

interface SessionExpireAction {
  type: string
  payload: {
    tokenExpired: boolean
    error?: string
  }
}

export interface ErrorResponse {
  status: number
  statusText: string
  ok: boolean
  error: string
  type?: string | null
}

const fetchAPI = async (
  endpoint: string,
  options: RequestInit = {},
  dispatch?: DispatchFunction | null,
  source: ApiSource | null | undefined = 'adminapi'
) => {
  const baseURL = source !== 'wapi' ? Config.ADMIN_API_URL : Config.WAPI_URL

  const token = getToken()

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    credentials: 'include',
    Authorization: token ? `Bearer ${token}` : '',
    ...options.headers,
  }

  const fetchOptions: RequestInit = {
    method: options.method ?? 'GET',
    headers,
  }

  if (options.body) {
    fetchOptions.body = typeof options.body === 'string' ? options.body : JSON.stringify(options.body)
  }

  try {
    const trimmedEndpoint = endpoint.startsWith('/') ? endpoint.slice(1) : endpoint
    const response = await fetch(`${baseURL}/${trimmedEndpoint}`, fetchOptions)

    const data = await response.json()

    if (!response || !response.ok) {
      const errorResponse: ErrorResponse = {
        status: response?.status || 0,
        statusText: response?.statusText || '',
        ok: response?.ok || false,
        error: data?.detail || data?.message || data?.error || 'Something went wrong',
        type: response?.type || null,
      }

      // Logout if token is expired or invalid
      // TODO: Re-think the condition for 403, when we apply role-based permission
      if (response?.status === 401 || response?.status === 403) {
        if (dispatch)
          dispatch(
            sessionExpire({
              tokenExpired: true,
              error: 'Your session has expired or is invalid',
            })
          )
      }

      return errorResponse
    }

    data.ok = true

    return data
  } catch (error) {
    if (Config.ENV === 'development') {
      console.error('Error while consuming API: ', error)
    }
  }
}

const apiService = {
  get: async (endpoint: string, headers: HeadersInit = {}, dispatch?: DispatchFunction | null, source?: ApiSource) =>
    await fetchAPI(endpoint, { method: 'GET', headers }, dispatch, source),
  post: async (
    endpoint: string,
    body: BodyPayload,
    headers: HeadersInit = {},
    dispatch?: DispatchFunction | null,
    source?: ApiSource
  ) => {
    const requestBody: string = typeof body === 'string' ? body : JSON.stringify(body)
    return await fetchAPI(endpoint, { method: 'POST', headers, body: requestBody }, dispatch, source)
  },
  put: async (
    endpoint: string,
    body: BodyPayload,
    headers: HeadersInit = {},
    dispatch?: DispatchFunction | null,
    source?: ApiSource
  ) => {
    const requestBody: string = typeof body === 'string' ? body : JSON.stringify(body)
    return await fetchAPI(endpoint, { method: 'PUT', headers, body: requestBody }, dispatch, source)
  },
  patch: async (
    endpoint: string,
    body: BodyPayload,
    headers: HeadersInit = {},
    dispatch?: DispatchFunction | null,
    source?: ApiSource
  ) => {
    const requestBody: string = typeof body === 'string' ? body : JSON.stringify(body)
    return await fetchAPI(endpoint, { method: 'PATCH', headers, body: requestBody }, dispatch, source)
  },
  delete: async (endpoint: string, headers: HeadersInit = {}, dispatch?: DispatchFunction | null, source?: ApiSource) =>
    await fetchAPI(endpoint, { method: 'DELETE', headers }, dispatch, source),
  getWAPI: async (endpoint: string, headers: HeadersInit = {}, dispatch?: DispatchFunction | null) =>
    await apiService.get(endpoint, headers, dispatch, 'wapi'),
  deleteWAPI: async (endpoint: string, headers: HeadersInit = {}, dispatch?: DispatchFunction | null) =>
    await fetchAPI(endpoint, { method: 'DELETE', headers }, dispatch, 'wapi'),
  postWAPI: async (
    endpoint: string,
    body: BodyPayload,
    headers: HeadersInit = {},
    dispatch?: DispatchFunction | null
  ) => await apiService.post(endpoint, body, headers, dispatch, 'wapi'),
  putWAPI: async (endpoint: string, body: BodyPayload, headers: HeadersInit = {}, dispatch?: DispatchFunction | null) =>
    await apiService.put(endpoint, body, headers, dispatch, 'wapi'),
}

export default apiService
