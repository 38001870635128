/**src/components/features/billing/priceTierSlice.ts*/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store'
import apiService from '../../services/apiService'
import { PriceTiersState, PriceTier} from './interfaces'

const initialState: PriceTiersState = {
  priceTiers: [],
  priceTier: null,
  loading: false,
  error: null,
};

export const priceTierSlice = createSlice({
  name: 'priceTiers',
  initialState,
  reducers: {
    setPriceTiers(state, action: PayloadAction<PriceTier[]>) {
        state.priceTiers = action.payload;
        state.loading = false;
        state.error = null;
    },
    setPriceTier: (state, action: PayloadAction<PriceTier|null>) => {
        state.priceTier = action.payload
        state.loading = false;
        state.error = null;
    },
    setLoading(state, action: PayloadAction<boolean>) {
        state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
        state.error = action.payload;
        state.loading = false;
    }
  },
});

export const { setPriceTiers, setPriceTier, setLoading, setError} = priceTierSlice.actions;

export const fetchPriceTiers = (force = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    const { priceTiers } = getState().priceTiers;
    if (priceTiers.length && !force) {
        dispatch(setLoading(false));
        return;
    } else {
        try{
        const response = await apiService.get('/price_tiers/?page=1', {}, dispatch);
        if (!response.ok) {
            dispatch(setError(response.error || 'Something went wrong'));
            return;
        }
        dispatch(setPriceTiers(response.data));
        } catch (error) {
            dispatch(setError((error as Error).message || 'Something went wrong'));
        }
    }
};

export const fetchPriceTier = (id: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    if(id === 0){
        dispatch(setPriceTier(null));
        dispatch(setLoading(false));
        return;
    }
    const { priceTier } = getState().priceTiers;
    if (priceTier?.id === id) {
        dispatch(setLoading(false));
        return;
    } else {
        try{
        const response = await apiService.get(`/price_tiers/${id}`, {}, dispatch);
        if (!response.ok) {
            dispatch(setError(response.error || 'Something went wrong'));
            return;
        }
        dispatch(setPriceTier(response.data));
        } catch (error) {
            dispatch(setError((error as Error).message || 'Something went wrong'));
        }
    }
};

export const createPriceTier = (priceTier: PriceTier) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try{
        const response = await apiService.post('/price_tiers/', JSON.stringify(priceTier), {}, dispatch);
        if (!response.ok) {
            dispatch(setError(response.error || 'Something went wrong'));
            return;
        }
        dispatch(fetchPriceTiers(true));
    } catch (error) {
        dispatch(setError((error as Error).message || 'Something went wrong'));
    }
};

export const updatePriceTier = (priceTier: PriceTier) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try{
        const response = await apiService.put(`/price_tiers/${priceTier.id}`, JSON.stringify(priceTier), {}, dispatch);
        if (!response.ok) {
            dispatch(setError(response.error || 'Something went wrong'));
            return;
        }
        dispatch(fetchPriceTiers(true));
    } catch (error) {
        dispatch(setError((error as Error).message || 'Something went wrong'));
    }
};

export const deletePriceTier = (priceTier: PriceTier) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try{
        const response = await apiService.delete(`/price_tiers/${priceTier.id}`, {}, dispatch);
        if (!response.ok) {
            dispatch(setError(response.error || 'Something went wrong'));
            return;
        }
        dispatch(fetchPriceTiers(true));
    } catch (error) {
        dispatch(setError((error as Error).message || 'Something went wrong'));
    }
};

export default priceTierSlice.reducer;