import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../store'
import apiService from '../../services/apiService'
import { DataIndexerState } from './interfaces'



const initialState: DataIndexerState = {
  loading: false,
  error: null,
};

const dataIndexerSlice = createSlice({
  name: 'dataIndexer',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  }
});

export const { setLoading, setError } = dataIndexerSlice.actions;

export const resetIndexer = (index : string | null) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await apiService.post(`/indexer/${index}/re-index`, JSON.stringify({}), {}, dispatch);
    dispatch(setLoading(false));
    if (!response.ok) {
      throw new Error(response.error);
    }
  } catch (error) {
    dispatch(setError((error as Error).message || 'Something went wrong'));
  }
  dispatch(setLoading(false));
};


export default dataIndexerSlice.reducer;
